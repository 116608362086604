import React, { useContext, useEffect } from "react";
import Navbar from "../sharedNew/navbar";
import MeetingContaint from "../componentsNew/meetingPage/MeetingContaint";
import { AppContext } from "../Context/AppContext";
import { useNavigate } from "react-router-dom";

const MeetingPage = () =>{
    const { logout, profileData } = useContext(AppContext);
    const navlink = useNavigate();

    const NavButtons = [{
      path: "/pricing-plans", title: "PRICING PLAN"
    }]

    // useEffect(()=>{
    //   if(!profileData){
    //     navlink('/signin');
    //   }
    // },[])

    return (
        <>
          <Navbar NavButtons={NavButtons}/>
          <MeetingContaint/>
        </>
    );
};

export default MeetingPage;