import React from "react";
import ReactDOM from "react-dom/client";
// import "./style/main.scss";
import "./styleNew/style.css";
import "./styleNew/media.css";
import App from "./App";

import { Modal } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import bootstrap from 'bootstrap';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <App />
);
