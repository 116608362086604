import React, { useState, useRef, useContext } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { AppContext } from "../../Context/AppContext";
import Navbar from "../../sharedNew/navbar";
import '../../styleNew/login.css';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = secureLocalStorage.getItem("userId");
  const { profileData, isLoading, ProfileData } = useContext(AppContext);

  const [formState, setFormState] = useState({
    firstName: "",
    profileImage: profileData?.picture,
    errors: {},
  });
  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormState({
          ...formState,
          profileImage: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      errors: {},
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (
      (!formState.firstName || formState.firstName.trim() === "") &&
      formState.profileImage === profileData?.picture
    ) {
      errors.firstName = "Name or Picture is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormState({
        ...formState,
        errors,
      });
    } else {
      const payload = {
        userid: userId,
        profile_picture: formState.profileImage,
      };

      if (formState.firstName.trim() !== "") {
        payload.name = formState.firstName;
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/update_profile`, payload)
        .then((res) => {
          toast.success(res.data);
          ProfileData();
          navigate("/settings");
          //  console.log(res);
        })
        .catch((err) => {
          //  console.log(err);
          toast.error("Issue updating profile data.");
        });
    }
  };

  return (
    <>
    <Navbar/>
  
     <div className="container z-3">
       <div className="d-flex align-items-center justify-content-center signup-form">
          <form onSubmit={handleSubmit}>
            <div className="right-container v-center flex-column w-100">
              <h4 className="fw-bold text-center text-light mb-3">Edit Profile</h4>
              <div
                className="profile-img cursor-pointer"
                onClick={handleDivClick}
              >
                <div className="d-flex align-items-center justify-content-center">

                {formState?.profileImage && (
                  <img
                    src={formState?.profileImage}
                    alt="profile"
                    className="cursor-pointer profile-pic "
                    width={120}
                  />
                )}
                  
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Icon
                    icon="f7:camera-fill"
                    width={24}
                    height={24}
                    color="#FFFFFF"
                  />
                  <p className="text-small fw-normal text-center">
                    Click to change photo
                  </p>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              <div className="d-flex flex-column gap-1 emailInput">
                <p className="text-small fw-normal">Name</p>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Name"
                  value={formState.firstName}
                  onChange={handleInputChange}
                  className="modal-input mb-2"
                />
                {formState.errors.firstName && (
                  <p className="text-small text-red">
                    {formState.errors.firstName}
                  </p>
                )}
                <button
                  type="submit"
                  className="dash-pink-btn fw-bold w-100 save-btn signInn"
                >
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Settings;
