import React, { useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import googleBtn from "../assets/images/signIn/google.png";
import { NavLink, useNavigate } from "react-router-dom";
import { toast,  } from "react-toastify";
import axios from "axios";
import "../styleNew/login.css";
import Navbar from "../sharedNew/navbar";

const PassRecover = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = formData;

    if (!email) {
      toast.error("Email is required");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address");
      return;
    }
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/forgot_password`, {
        email: formData.email,
      })
      .then((res) => {
        // console.log(res);
        toast.success(res?.data?.message);
        // console.log(formData);
        navigate("/verify-code", { state: { fromRoute2: true, formData } });
      })
      .catch((err) => {
        // console.log(err);
        // setFormErrors("Verification Code is wrong");
        toast.error(err?.response?.data?.detail || "Enter a valid code" );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
    <Navbar />
      <div className="container z-3">
        <div className="d-flex align-items-center justify-content-center signup-form">
            <form onSubmit={handleSubmit} className="h-center flex-column">
              <h2>Password Recovery</h2>
              <div className="emailInput">
                <label className="mb-2" for="email">
                  Enter your Email to receive the Verification Code
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Your Email"
                />
              </div>
              <button className="signInn" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
    </>
  );
};

export default PassRecover;
