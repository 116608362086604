import React, { useContext, useEffect, useState } from "react";
import businessmanPic8 from "../../assetsNew/image/businessman/businessman8.png";

import ShareNetwork from "../../assetsNew/image/ShareNetwork.png";
import LinkLogo from "../../assetsNew/image/Link.png";
import SharePopUpModal from "../../modalsNew/SharePopUpModal";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import axios from "axios";

function Recommended() {
  const location = useLocation();
  const { recommendedTopic, setShareLink, setPersonas } = useContext(AppContext);
  const navigate = useNavigate();
  const profileData = secureLocalStorage.getItem("profileData");
  const userId = secureLocalStorage.getItem("userId");
  const usertype = secureLocalStorage.getItem("usertype");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const createNewPersona = async (title) => {
    try {
      const payload = {
        userid: userId ? userId : "",
        topic: title,
        number_of_persona: 1,
        diversity: "false",
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
        payload
      );
  
      const personasData = response.data.personas;
      setPersonas(personasData);
      localStorage.setItem("personas", JSON.stringify(personasData));
  
      // Only proceed to the second API call if the first one is successful
      if (userId) {
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
            { 
              userid: userId, 
              persona_name: personasData[0]?.name, 
              persona_description: personasData[0]?.description, 
              image: "../assetsNew/image/user.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
              isFavourite: "0" 
            }
          );
        } catch (error) {
          console.error("Error generating persona:", error);
          // You can handle persona generation error here, but the flow will continue
        }
      }
    } catch (error) {
      console.error("Error fetching personas:", error);
      // If there's an error in fetching personas, handle it here, but don't break the flow
    }
  };
  
  const handleMeetingPage = async (title) => {
    if(isLoading){
      return;
    }

    try {
      setSelectedItem(title);
      setIsLoading(true);
      await createNewPersona(title);
      setIsLoading(false);
      localStorage.setItem("topic", title); 
      navigate('/meeting');
    } catch (error) {
      console.error("Error in handling meeting page:", error);
    }
  };
  
  
  const handleCopyLink = (title)=>{
    const copyFullLink = window.location.href;
    const EecoderTitle = encodeURIComponent(title);
    const FullLink = (`${copyFullLink}share/${EecoderTitle}`);

    navigator.clipboard
    .writeText(FullLink)
    .then(() => {
      // Show success message
      toast.success("Link copied successfully!");
    })
    .catch((err) => {
      // Handle error
      console.error("Failed to copy link: ", err);
      toast.error("Failed to copy the link. Please try again.");
    });

  }

  const handleShareVia = (title)=>{
    const copyFullLink = window.location.href;
    const EecoderTitle = encodeURIComponent(title);
    const FullLink = (`${copyFullLink}share/${EecoderTitle}`);

    setShareLink(FullLink);
  }

  return (
    <>
      {/* Recommended Topics  */}
      <div className="container pt-2">
        <h4 className="text-white">Recommended Topics</h4>
        <div className="row gx-3 gy-3 text-white">
          {recommendedTopic && recommendedTopic.map((card, index)=>(

          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
            <div className={`recommended-card Customzindex-${index+1}`}>
              <img className="reco-card-image" src={card?.image || businessmanPic8} alt="" onClick={()=>handleMeetingPage(card?.topic_title)}/>
              <div className="main-info">
                <p 
                  onClick={()=>handleMeetingPage(card?.topic_title)}>{isLoading && selectedItem === card?.topic_title ? 'Loading ...' : card?.topic_title}</p>
                <p 
                  className={isLoading && selectedItem === card?.topic_title ? 'pb-3' : ''}
                  onClick={()=>handleMeetingPage(card?.topic_title)}>{isLoading && selectedItem === card?.topic_title ? 'Loading' : card?.topic_details.slice(0,50)} ...</p>
                {isLoading && selectedItem === card?.topic_title ? '' : (
                <div className="d-flex align-items-end justify-content-end btn-group z-3">
                  <img
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    src={ShareNetwork}
                    alt=""
                  />
                  <ul className="dropdown-menu dropdown-menu-end ">
                    <li>
                      <img className="ms-3" src={LinkLogo} alt="link" />
                      <button className="dropdown-item mt-1" type="button" onClick={()=>handleCopyLink(card?.topic_title)}>
                        Copy link
                      </button>
                    </li>
                    <hr />
                    <li
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#SharePopUp"
                    >
                      <img
                        className="ms-3"
                        src={ShareNetwork}
                        alt="link"
                      />
                      <button className="dropdown-item ps-3" type="button" onClick={()=>handleShareVia(card?.topic_title)}>
                        Share via ...
                      </button>
                    </li>
                  </ul>
                </div>
                )}
              </div>
            </div>
          </div>

          ))}
        </div>
      </div>
      <SharePopUpModal/>
    </>
  );
}

export default Recommended;
