import React from 'react'

function Footer() {
  return (
    // -- Footer Section --
    <footer className="container-fluid footer text-center mt-sm-1 mt-lg-4 py-3">
        <p>© 2025 Slara. All rights reserved.</p>
    </footer>

  )
}

export default Footer