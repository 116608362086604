import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import googleBtn from "../assets/images/signIn/google.png";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../Context/AppContext";
import "../styleNew/login.css";
import Navbar from "../sharedNew/navbar";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const MainSignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { ProfileData , setTime, setUserHasPlan, loginFromPath, personas } = useContext(AppContext);
  const baseUrl = window.location.origin;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const togglePasswordVisibility = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleGenaratePersona = async (userId) => {
    if (!userId) {
      console.error("User ID is required to generate personas.");
      return;
    }
  
    try {
      if (personas && personas.length > 0) {
        const promises = personas.map((persona) =>
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
              {
                userid: userId,
                persona_name: persona?.name,
                persona_description: persona?.description,
                image: persona?.image || "../assetsNew/image/user.png",
                voice_settings: {
                  stability: "0.71",
                  similarity_boost: "0.5",
                  style: "0",
                  speaker_boost: "false",
                },
                usertype: "registered",
                isFavourite: "0",
              }
            )
            .catch((error) => {
              console.error(`Error generating persona for ${persona?.name}:`, error);
              return null; // Return null or any default value if the request fails
            })
        );
  
        await Promise.allSettled(promises);
      } else {
        console.warn("No personas available to generate.");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const handleGetStarted = async (name, price, email) => {
      try {
        const payload = email
          ? {
              userid: "",
              amount: price,
              plan_name: name,
              email: email,
              base_url: baseUrl || "https://www.slara.ai",
            }
          : {
              userid: "",
              amount: 0.0,
              plan_name: name,
              email: "abc@gmail.com",
              base_url: "",
            };

        // Send request to activate the free trial
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
          payload
        );

        // Handle the response based on different cases
        if (response.data.save === "save plan") {
          setUserHasPlan("Free_Trial");
          // toast.success("Free trial activated!");
          await ProfileData();
          navigate("/");
        } else if (response.data["Already subscribed"]) {
          // toast.error(
          //   response.data["Already subscribed"] ||
          //     "You have already used your free trial!"
          // );
        } else if (response.data.Response) {
          const { userid, token, plan, guest_id } = response.data.Response;

          // Save necessary data in secure storage
          secureLocalStorage.setItem("authToken", token);
          secureLocalStorage.setItem("userId", userid);
          secureLocalStorage.setItem("guestId", guest_id);
          secureLocalStorage.setItem("usertype", "guest");

          setUserHasPlan(plan);
          // toast.success("Free Trial Activated!");
          await ProfileData();
          navigate("/");
        } else {
          // toast.error("You already have a plan");
        }
      } catch (error) {
        console.error("Error activating free trial:", error);
        // toast.error("Something went wrong. Please try again later.");
      }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (!email && !password) {
      toast.error("Please fill the required fields");
      return;
    } else if (!email) {
      toast.error("Invalid email address");
      return;
    }

    if (!password) {
      toast.error("Password is required");
      return;
    }
    setTime({ minute: 0, second: 0 });        
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        email: formData.email,
        password: formData.password,
      })
      .then((res) => {
        secureLocalStorage.setItem("usertype" , "registered")
        secureLocalStorage.setItem("authToken", res?.data?.token);
        secureLocalStorage.setItem("userId", res?.data?.userid);
        ProfileData();
        if (res?.data?.plan){
          if(loginFromPath === 'meeting' && personas?.length !== 0){
            handleGenaratePersona(res?.data?.userid);
            navigate("/meeting");
          }else{
            navigate("/");
          }
          toast.success("Logged in Successfully");
        }else{
          //add free_plan 
          handleGetStarted("Free_Trial", "00.00", formData.email);
          if(loginFromPath === 'meeting' && personas?.length !== 0){
            handleGenaratePersona(res?.data?.userid);
            navigate("/meeting");
          }else{
            navigate("/");
          }
          toast.success("Logged in Successfully");
        }
        setFormData({
          name: "",
          password: "",
        });
      })
      .catch((err) => {
 
        toast.error(err?.response?.data?.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleLogin = (userInfo) => {

    setTime({ minute: 0, second: 0 });        
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/google-login`, {
        confirm_password: "",
        email: userInfo?.email,
        profile_pic: userInfo.picture,
        guest_id: "",
        password: "",
        time: "",
        username: userInfo?.given_name
      })
      .then((res) => {
        secureLocalStorage.setItem("usertype" , "registered")
        secureLocalStorage.setItem("authToken", res?.data?.token);
        secureLocalStorage.setItem("userId", res?.data?.userid);
        ProfileData();
        if (res?.data?.plan){
          if(loginFromPath === 'meeting' && personas?.length !== 0){
            handleGenaratePersona(res?.data?.userid);
            navigate("/meeting");
          }else{
            navigate("/");
          }
          toast.success("Logged in Successfully");
        }else{
          //add free_plan 
          handleGetStarted("Free_Trial", "00.00", userInfo?.email);
          if(loginFromPath === 'meeting' && personas?.length !== 0){
            handleGenaratePersona(res?.data?.userid);
            navigate("/meeting");
          }else{
            navigate("/");
          }
          toast.success("Logged in Successfully");
        }
        setFormData({
          name: "",
          password: "",
        });
      })
      .catch((err) => {
 
        toast.error("Logged in Faild !");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);


  return (
    <>
      <Navbar/>
      <div className="container z-3">
        <div className="d-flex align-items-center justify-content-center signup-form" style={{height: "90vh"}}>
            <form onSubmit={handleSubmit}>
              <h2 className="text-light text-center">Sign In</h2>
              <div className="emailInput">
                <label className="mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="emailInput m-auto">
                <label className="mb-2" htmlFor="password">
                  Enter Password
                </label>
                <div className="pswd-div ">
                  <input
                    type={formData.showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <Icon
                    className="iconEye ms-1"
                    onClick={togglePasswordVisibility}
                    icon={
                      formData.showPassword
                        ? "fa-solid:eye"
                        : "fa6-solid:eye-slash"
                    }
                  />
                </div>
              </div>

             

              <button className="signInn" type="submit" disabled={loading}>
                {loading ? "Signing In..." : "Sign In"}
              </button>
              
              <div className="flex-div">
              <div className="emailInput m-auto">
                <p>Don't have an account? <span onClick={()=>navigate('/signup')}
                >Sign Up</span></p>
              </div>

              <div className="forgot d-flex justify-content-end w-100">
                <NavLink to="/forgetpassword">
                  <span className="forget-pass">Forgot Password?</span>
                </NavLink>
              </div>
              </div>

              <div className="text-center m-auto">
                <span className="my-2 or text-white">-- OR --</span>
              </div>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const userInfo = jwtDecode(credentialResponse?.credential);
                  handleGoogleLogin(userInfo);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
              
              {/*
              <div className="googleBtn">
                <button type="button">
                  <img src={googleBtn} alt="" />
                  <span>Continue with Google</span>
                </button>
              </div> */}
            </form>
          </div>
        </div>

    </>
  );
};


const SignIn = () => {
  return(
    <GoogleOAuthProvider clientId="865490019787-makuufqia2907sdm8kpl7l4epm7g2ksq.apps.googleusercontent.com">
      <MainSignIn/>
    </GoogleOAuthProvider>
  )
}

export default SignIn;

// import React, { useState } from "react";
// import logo from "../assets/images/signIn/logoS.png";
// import { Icon } from "@iconify/react";
// import googleBtn from "../assets/images/signIn/google.png";
// import { NavLink, useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";

// const SignIn = () => {
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [lastError, setLastError] = useState("");

//   function tooglepasswordvisibility() {
//     setShowPassword(!showPassword);
//   }

//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     showPassword: false,
//   });

//   const validateEmail = (email) => {
//     const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const validatePassword = (password) => {
//     const re = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
//     return re.test(password);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const { email, password } = formData;

//     if (!email && !password) {
//       showError("Please fill the required fields");
//       setLoading(false);
//       return;
//     } else if (!validateEmail(email)) {
//       showError("Invalid email address");
//       setLoading(false);
//       return;
//     }

//     if (!password) {
//       showError("Password is required");
//       setLoading(false);
//       return;
//     } else if (!validatePassword(password)) {
//       showError(
//         "Password must be at least 8 characters long and contain a mix of letters, numbers, and symbols."
//       );
//       setLoading(false);
//       return;
//     }

//     axios
//       .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
//         email: formData.email,
//         password: formData.password,
//       })
//       .then((res) => {
//         secureLocalStorage.setItem("authToken", res?.data?.token);
//         secureLocalStorage.setItem("userId", res?.data?.userid);
//         navigate("/dashboard");
//         toast.success("Logged in Successfully");

//         setFormData({
//           email: "",
//           password: "",
//         });
//         setLastError("");
//       })
//       .catch((err) => {
//         showError(err?.response?.data?.detail);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   const showError = (message) => {
//     if (message !== lastError) {
//       toast.error(message);
//       setLastError(message);
//     }
//   };

//   const handleGoogleSignIn = (e) => {
//     e.preventDefault();
//     // Your logic for Google Sign-In
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="signInwrapper">
//         <div className="nav">
//           <div className="logo">
//             <img src={logo} alt="" />
//           </div>
//           <NavLink to="/signup">
//             <button>SIGN UP</button>
//           </NavLink>
//         </div>
//         <div className="centerDiv">
//           <h5>Sign In</h5>
//           <div className="userData">
//             <form onSubmit={handleSubmit}>
//               <div className="emailInput">
//                 <label className="mb-2" htmlFor="email">
//                   EMAIL
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   placeholder="Enter Your Email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div className="passwordInput">
//                 <label className="mb-2" htmlFor="password">
//                   ENTER PASSWORD
//                 </label>
//                 <div className="pswd-div">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     name="password"
//                     placeholder="Enter Your Password"
//                     value={formData.password}
//                     onChange={handleChange}
//                   />
//                   <Icon
//                     className="iconEye"
//                     onClick={tooglepasswordvisibility}
//                     icon={
//                       showPassword
//                         ? "fa-solid:eye"
//                         : "fa6-solid:eye-slash"
//                     }
//                   />
//                 </div>
//               </div>

//               <button className="signInn" type="submit" disabled={loading}>
//                 {loading ? "Signing In..." : "Sign In"}
//               </button>
//               <div className="forgot d-flex justify-content-end w-100">
//                 <NavLink to="/forgetpassword">
//                   <button>Forgot Password?</button>
//                 </NavLink>
//               </div>
//               <span className="or h-center my-2 text-black">OR</span>

//               <div className="googleBtn">
//                 <button>
//                   <img src={googleBtn} alt="" />
//                   <span>Continue with Google</span>
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SignIn;
