import React, { useContext } from 'react';
import deleteLogo from '../assetsNew/image/deletelogo.png';
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { AppContext } from '../Context/AppContext';

const RemovePersonaPopup = ({personaOne}) => {
  const { personas, setFetchedPersonas, setPersonas } = useContext(AppContext);
  const userId = secureLocalStorage.getItem("userId");

  const handleDeletePersona = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/persona/delete_persona`, {
        userid: userId,
        persona_name: personaOne?.name,
      })
      .then((response) => {
        toast.success(`Persona ${personaOne?.name} deleted successfully`);
      })
      .catch((error) => {
        // toast.error("Issue deleting persona");
        // console.log(error);
      });
    setFetchedPersonas((prev) => prev.filter((presona) => presona.name !== personaOne?.name));
    const updatedPersonas = personas.filter((presona) => presona.name !== personaOne?.name);
    setPersonas(updatedPersonas);
    localStorage.setItem("personas", JSON.stringify(updatedPersonas));
  };


  return (
    <div
      className="modal fade"
      id="removePersonaPopup"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content custom-modal persona-model-popups">
          <i
            className="fa-sharp fa-solid fa-circle-xmark"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></i>
          <img src={deleteLogo} alt="deletelogo" />
          <h2>
            Want this persona from <br />
            discussion to be <span>Removed</span>?
          </h2>
          <div className="mt-3 my-2 d-flex gap-3 justify-content-center align-items-center">
            <button 
              className="first-button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >Cancel</button>
            <button 
              className="second-button" 
              onClick={handleDeletePersona}
              
              data-bs-dismiss="modal"
              aria-label="Close"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemovePersonaPopup;
