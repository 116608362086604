import React, { useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import googleBtn from "../assets/images/signIn/google.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Navbar from "../sharedNew/navbar";
import "../styleNew/login.css";

const SetNewPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state.email);
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  function tooglepasswordvisibility() {
    setshowPassword(!showPassword);
  }

  const [showPassword2, setshowPassword2] = useState(false);
  function tooglepasswordvisibility2() {
    setshowPassword2(!showPassword2);
  }

  const [formData, setFormData] = useState({
    password: "",
    rePassword: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = (values) => {
    const errors = {};

    const passwordRegex =
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;

    if (values.password == "") {
      errors.password = "Password is required";
    } else if (!passwordRegex.test(values.password)) {
      errors.password =
        "Password must be at least 8 characters long and contain a mix of letters, numbers, and symbols";
    }

    if (values.rePassword === "") {
      errors.rePassword = "Re-enter Password is required";
    } else if (values.password !== values.rePassword) {
      errors.rePassword = "Passwords do not match. Please try again.";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formData);
    setFormErrors(errors);

    // Check if all required fields are empty
    const allFieldsEmpty = Object.values(formData).every(
      (value) => value.trim() === ""
    );
    if (allFieldsEmpty) {
      toast.error("Please fill in all the required fields");
      return;
    }

    if (Object.keys(errors).length > 0) {
      // Display error toasts and update state for highlighting
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
      setFormErrors(errors);
      return;
    }
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/reset_password`, {
        email: location.state.email,
        code: location.state.code,
        new_password: formData.password,
        confirm_password: formData.rePassword,
      })
      .then((res) => {
        // console.log(res);
        // secureLocalStorage.setItem("authToken" , res.data.token)
        navigate("/signin");
        toast.success("Form submitted successfully!");

        setFormData({
          name: "",
          password: "",
        });
      })
      .catch((err) => {
        // console.log(err.response.data.detail);
        toast.error(err?.response?.data?.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Navbar/>
      
        <div className="container z-3">
          <div className="d-flex align-items-center justify-content-center signup-form">

            <form
              onSubmit={handleSubmit}
              action=""
              >
              <h2 className="text-center">Set New Password</h2>
              <div className="emailInput">
                <label className="mb-2" for="password">ENTER PASSWORD</label>
                <div className="pswd-div">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter Password"
                  />
                  <Icon
                    className="iconEye ms-1"
                    onClick={tooglepasswordvisibility}
                    icon={showPassword ? "fa-solid:eye" : "fa6-solid:eye-slash"}
                  />
                </div>
              </div>
              <div className="emailInput ">
                <label for="password">RE-ENTER PASSWORD</label>
                <div className="pswd-div">
                  <input
                    type={showPassword2 ? "text" : "password"}
                    name="rePassword"
                    value={formData.rePassword}
                    onChange={handleChange}
                    placeholder="Re-Enter Password"
                  />
                  <Icon
                    className="iconEye ms-1"
                    onClick={tooglepasswordvisibility2}
                    icon={
                      showPassword2 ? "fa-solid:eye" : "fa6-solid:eye-slash"
                    }
                  />
                </div>
              </div>
              {/* <NavLink to="/signin"> */}
              <button className="signInn" disabled={loading}>
                {loading ? "Submitting..." : "Continue"}
              </button>

              {/* </NavLink> */}
            </form>
          </div>
        </div>
    </>
  );
};

export default SetNewPass;
