import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/signIn/logoS.png";
import { Icon } from "@iconify/react";
import googleBtn from "../assets/images/signIn/google.png";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../Context/AppContext";
import Navbar from "../sharedNew/navbar";
import "../styleNew/login.css";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";


const MainSignUp = () => {
  const { ProfileData , setTime, setUserHasPlan, personas} = useContext(AppContext);
  const navigate = useNavigate();
  const { formattedTime, loginFromPath } = useContext(AppContext);
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseUrl = window.location.origin;

  function tooglepasswordvisibility() {
    setshowPassword(!showPassword);
  }

  const [showPassword2, setshowPassword2] = useState(false);
  function tooglepasswordvisibility2() {
    setshowPassword2(!showPassword2);
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    rePassword: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,14}$/;

    if (values.name == "") {
      errors.name = "Name is required";
    } else if (values.name.length > 50) {
      errors.name =
        "Username is too long. Please enter a username with fewer than 50 characters";
    } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
      errors.name = "Invalid characters detected. Please remove symbols.";
    }

    if (values.email == "") {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
    }
    if (values.password == "") {
      errors.password = "Password is required";
    }
    //   else if (values.password.length < 8 || values.password.length > 20) {
    //   errors.password = "Password length must be between 8 and 20 characters";
    // }
    else if (!passwordRegex.test(values.password)) {
      errors.password =
        "Password must be at least 8 characters long and contain a mix of letters, numbers, and symbols";
    }

    if (values.rePassword === "") {
      errors.rePassword = "Re-enter Password is required";
    } else if (values.password !== values.rePassword) {
      errors.rePassword = "Passwords do not match. Please try again.";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formData);
    setFormErrors(errors);

    // Check if all required fields are empty
    const allFieldsEmpty = Object.values(formData).every(
      (value) => value.trim() === ""
    );
    if (allFieldsEmpty) {
      toast.error("Please fill in all the required fields");
      return;
    }

    if (Object.keys(errors).length > 0) {
      // Display error toasts and update state for highlighting
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
      setFormErrors(errors);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        {
          username: formData.name,
          email: formData.email,
          password: formData.password,
          confirm_password: formData.rePassword,
          guest_id: secureLocalStorage.getItem("guestId"),
          time: secureLocalStorage.getItem("time"),
        }
      );

      // console.log(response.data);
      toast.success(response?.data?.Response);
      navigate("/verify-code", { state: { fromRoute1: true, formData } });
    } catch (error) {
      // console.error('There was a problem with the API call:', error.response.data.detail);
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false);
    }
  };

  const handleGetStarted = async (name, price, email) => {
    try {
      const payload = email
        ? {
            userid: "",
            amount: price,
            plan_name: name,
            email: email,
            base_url: baseUrl || "https://www.slara.ai",
          }
        : {
            userid: "",
            amount: 0.0,
            plan_name: name,
            email: "abc@gmail.com",
            base_url: "",
          };

      // Send request to activate the free trial
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
        payload
      );

      // Handle the response based on different cases
      if (response.data.save === "save plan") {
        setUserHasPlan("Free_Trial");
        // toast.success("Free trial activated!");
        await ProfileData();
        navigate("/");
      } else if (response.data["Already subscribed"]) {
        // toast.error(
        //   response.data["Already subscribed"] ||
        //     "You have already used your free trial!"
        // );
      } else if (response.data.Response) {
        const { userid, token, plan, guest_id } = response.data.Response;

        // Save necessary data in secure storage
        secureLocalStorage.setItem("authToken", token);
        secureLocalStorage.setItem("userId", userid);
        secureLocalStorage.setItem("guestId", guest_id);
        secureLocalStorage.setItem("usertype", "guest");

        setUserHasPlan(plan);
        // toast.success("Free Trial Activated!");
        await ProfileData();
        navigate("/");
      } else {
        // toast.error("You already have a plan");
      }
    } catch (error) {
      console.error("Error activating free trial:", error);
      // toast.error("Something went wrong. Please try again later.");
    }
  };

  const handleGenaratePersona = async (userId) => {
    if (!userId) {
      console.error("User ID is required to generate personas.");
      return;
    }
  
    try {
      if (personas && personas.length > 0) {
        const promises = personas.map((persona) =>
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
              {
                userid: userId,
                persona_name: persona?.name,
                persona_description: persona?.description,
                image: persona?.image || "../assetsNew/image/user.png",
                voice_settings: {
                  stability: "0.71",
                  similarity_boost: "0.5",
                  style: "0",
                  speaker_boost: "false",
                },
                usertype: "registered",
                isFavourite: "0",
              }
            )
            .catch((error) => {
              console.error(`Error generating persona for ${persona?.name}:`, error);
              return null; // Return null or any default value if the request fails
            })
        );
  
        await Promise.allSettled(promises);
      } else {
        console.warn("No personas available to generate.");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }; 

  const handleGoogleLogin = async (userInfo) => {

    setTime({ minute: 0, second: 0 }); 
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/google-login`,
        {
          confirm_password: "",
          email: userInfo?.email,
          profile_pic: userInfo.picture,
          guest_id: "",
          password: "",
          time: "",
          username: userInfo?.given_name
        }
      ).then((res) => {
        secureLocalStorage.setItem("usertype" , "registered")
        secureLocalStorage.setItem("authToken", res?.data?.token);
        secureLocalStorage.setItem("userId", res?.data?.userid);
        ProfileData();
        if (res?.data?.plan){
          if(loginFromPath === 'meeting' && personas?.length !== 0){
            handleGenaratePersona(res?.data?.userid);
            navigate("/meeting");
          }else{
            navigate("/");
          }
          toast.success("Logged in Successfully");
        }else{
          //add free_plan 
          handleGetStarted("Free_Trial", "00.00", userInfo?.email);

          if(loginFromPath === 'meeting' && personas?.length !== 0){
            handleGenaratePersona(res?.data?.userid);
            navigate("/meeting");
          }else{
            navigate("/");
          }
          toast.success("Logged in Successfully");
        }
      })
    } catch (error) {
      // console.error('There was a problem with the API call:', error.response.data.detail);
      toast.error("Logged in Faild !");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <Navbar/>

      <div className="container z-3">
          <div className="d-flex align-items-center justify-content-center signup-form mt-1">
            <form onSubmit={handleSubmit} action="">
              <h2 className="text-center text-light">Sign Up</h2>
              <div className="emailInput lg:mr-auto">
                <label className="mb-2" for="name">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Full Name"
                />
                {/* {formErrors.name && <span className="error">{formErrors.name}</span>} */}
              </div>

              <div className="emailInput lg:mr-auto">
                <label className="mb-2" for="email">
                  EMAIL
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                />
              </div>

              <div className="emailInput m-auto">
                <label className="mb-2" for="password">
                  ENTER PASSWORD
                </label>
                <div className="pswd-div">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter Password"
                  />
                  <Icon
                    className="iconEye ms-1"
                    onClick={tooglepasswordvisibility}
                    icon={showPassword ? "fa-solid:eye" : "fa6-solid:eye-slash"}
                  />
                </div>
              </div>

              <div className="emailInput m-auto">
                <label className="mb-2" for="repassword">
                  RE-ENTER PASSWORD
                </label>
                <div className="pswd-div">
                  <input
                    type={showPassword2 ? "text" : "password"}
                    name="rePassword"
                    value={formData.rePassword}
                    onChange={handleChange}
                    placeholder="Re-Enter Password"
                  />
                  <Icon
                    className="iconEye ms-1"
                    onClick={tooglepasswordvisibility2}
                    icon={
                      showPassword2 ? "fa-solid:eye" : "fa6-solid:eye-slash"
                    }
                  />
                </div>
              </div>

              <div className="emailInput m-auto">
                <p>Allready have an account? <span onClick={()=>navigate('/signin')}
                >Sign In</span></p>
              </div>

              <button type="submit" className="signInn" disabled={loading}>
                {loading ? "Signing Up..." : "Sign Up"}
              </button>
              {/* <div className="forgot d-flex justify-content-end w-100">
              <button>Forgot Password</button>
            </div> */}
            <div className="text-center m-auto">
              <span className="or my-2 text-white"> -- OR -- </span>
            </div>

            <GoogleLogin
              onSuccess={(credentialResponse) => {
                const userInfo = jwtDecode(credentialResponse?.credential);
                handleGoogleLogin(userInfo);
                // console.log('User Info: ',userInfo);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />

              {/* <div className="googleBtn">
                <button>
                  <img src={googleBtn} alt="" />
                  <span>Continue with Google</span>
                </button>
              </div> */}
            </form>
          </div>
      </div>

    </>
  );
};

const SignUp = () => {
  return(
    <GoogleOAuthProvider clientId="865490019787-makuufqia2907sdm8kpl7l4epm7g2ksq.apps.googleusercontent.com">
      <MainSignUp/>
    </GoogleOAuthProvider>
  )
}

export default SignUp;
