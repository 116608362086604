import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "../../assetsNew/image/Search.svg";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

function SearchBox() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const userId = secureLocalStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const usertype = secureLocalStorage.getItem("usertype");

  const { 
    setChatType, 
    profileData, 
    searchType,
    recommendedTopic,
    generalTopic,
    topicCategory,
    allPersonas,
    userHasPlan,
    personas,
    setPersonas
  } = useContext(AppContext);

  const topics = [
    { topic_title: "Startup Strategy Planning", topic_details: "An essential guide to developing strategies that set the foundation for a successful startup, covering key aspects like vision, goals, and execution." },
    { topic_title: "Business Model Validation", topic_details: "Learn how to test and refine your business model to ensure its viability and profitability in real-world markets." },
    { topic_title: "Funding & Investment", topic_details: "Discover strategies for securing funding and investment to fuel your business growth, from pitching to managing investor relations." },
    { topic_title: "Growth Hacking Techniques", topic_details: "Explore innovative techniques to accelerate business growth through marketing, customer acquisition, and retention strategies." },
    { topic_title: "Customer Acquisition", topic_details: "Understand the best practices and strategies for acquiring new customers and expanding your market reach." },
    { topic_title: "Pricing Strategy", topic_details: "A deep dive into pricing strategies that optimize profitability while remaining competitive in the market." },
    { topic_title: "Market Entry Planning", topic_details: "Learn how to plan for successful market entry, considering competition, customer needs, and local regulations." },
    { topic_title: "Competition Analysis", topic_details: "Understand the importance of competitive analysis and how to use it to position your product or service in the market." },
    { topic_title: "Lesson Planning Tips", topic_details: "Effective techniques for designing lesson plans that engage students and enhance learning outcomes." },
    { topic_title: "Student Engagement Strategies", topic_details: "Explore strategies to keep students motivated and engaged in the learning process, from interactive activities to feedback techniques." },
    { topic_title: "Classroom Management", topic_details: "Techniques for managing a classroom effectively, creating an environment conducive to learning and minimizing disruptions." },
    { topic_title: "Digital Teaching Tools", topic_details: "Discover a variety of digital tools that can support online and in-person teaching, improving student engagement and collaboration." },
    { topic_title: "Assessment Methods", topic_details: "A guide to various assessment methods that help evaluate student performance and ensure learning goals are met." },
    { topic_title: "Special Education Approaches", topic_details: "Learn about different strategies for teaching students with special needs, including personalized support and adapted teaching methods." },
    { topic_title: "Parent Communication", topic_details: "Effective communication strategies for keeping parents informed about their child's progress and fostering positive relationships." },
    { topic_title: "Patient Care Best Practices", topic_details: "Essential practices for providing high-quality patient care, including effective communication, treatment strategies, and patient safety." },
    { topic_title: "Healthcare Management", topic_details: "Explore the fundamentals of healthcare management, including operational efficiency, leadership, and regulatory compliance." },
    { topic_title: "Medical Ethics Discussions", topic_details: "Analyze complex ethical issues in the healthcare sector, including patient rights, privacy, and end-of-life decisions." },
    { topic_title: "Team Coordination", topic_details: "Learn how to improve team coordination within healthcare settings to ensure optimal patient outcomes and efficient workflows." },
    { topic_title: "Healthcare Technology Updates", topic_details: "Stay updated on the latest advancements in healthcare technology and how they are transforming patient care and hospital management." },
    { topic_title: "Patient Communication", topic_details: "Strategies for improving communication with patients to enhance trust, treatment adherence, and patient satisfaction." },
    { topic_title: "Work-Life Balance in Healthcare", topic_details: "Techniques for managing the demanding nature of healthcare jobs while maintaining personal well-being and work-life balance." },
    { topic_title: "Medical Research Analysis", topic_details: "Guidance on analyzing and interpreting medical research to stay informed on emerging trends and treatments." },
    { topic_title: "Dating Advice & Tips", topic_details: "Expert advice on navigating the world of dating, from first dates to building meaningful relationships." },
    { topic_title: "Building Better Relationships", topic_details: "Insights into creating and maintaining strong, healthy relationships through communication, trust, and mutual respect." },
    { topic_title: "Communication Skills", topic_details: "Learn the essential communication skills necessary for building and maintaining successful personal and professional relationships." },
    { topic_title: "Conflict Resolution", topic_details: "Strategies for resolving conflicts in relationships, from identifying root causes to finding constructive solutions." },
    { topic_title: "Long-distance Relationship Strategies", topic_details: "Effective methods for maintaining a strong, healthy long-distance relationship, including communication techniques and trust-building." },
    { topic_title: "Understanding Love Languages", topic_details: "Explore the five love languages and how understanding your partner's can help strengthen your relationship." },
    { topic_title: "Setting Healthy Boundaries", topic_details: "Learn how to establish and maintain healthy boundaries in relationships to foster mutual respect and understanding." },
    { topic_title: "Marriage Preparation", topic_details: "Guidance on preparing for marriage, including communication strategies, financial planning, and mutual goals." },
    { topic_title: "Trend Analysis & Forecasting", topic_details: "Learn how to analyze fashion trends and forecast future styles using data, intuition, and market research." },
    { topic_title: "Sustainable Fashion", topic_details: "A look into the growing movement of sustainable fashion, from eco-friendly materials to ethical production practices." },
    { topic_title: "Collection Development", topic_details: "Understanding the process of developing a fashion collection, from concept to production, while maintaining a cohesive theme." },
    { topic_title: "Fashion Business Strategy", topic_details: "Explore business strategies specific to the fashion industry, including brand development, marketing, and financial management." },
    { topic_title: "Material Sourcing", topic_details: "A guide to sourcing materials for fashion design, focusing on sustainability, quality, and cost-effectiveness." },
    { topic_title: "Design Portfolio Review", topic_details: "Learn how to review and improve a fashion design portfolio to showcase your creativity and skills to potential employers or clients." },
    { topic_title: "Brand Development", topic_details: "Techniques for developing a strong and recognizable fashion brand, from identity to marketing and customer loyalty." },
    { topic_title: "Fashion Marketing", topic_details: "Strategies for effectively marketing fashion products, including social media campaigns, influencer collaborations, and targeted advertising." },
    { topic_title: "Child Development Stages", topic_details: "An overview of the key stages of child development, including cognitive, emotional, and physical milestones." },
    { topic_title: "Parenting Techniques", topic_details: "Effective parenting strategies to support children's growth, from discipline methods to fostering emotional intelligence." },
    { topic_title: "Education Planning", topic_details: "Guidance on planning for your child's education, from early childhood to higher education, including academic and extracurricular activities." },
    { topic_title: "Work-Life Balance", topic_details: "Learn how to balance your career, family life, and personal well-being to reduce stress and increase productivity." },
    { topic_title: "Children's Health", topic_details: "Essential information on maintaining your child's health, including nutrition, exercise, and addressing common health issues." },
    { topic_title: "Family Activities", topic_details: "Ideas for fun and educational family activities that strengthen relationships and create lasting memories." },
    { topic_title: "Teen Communication", topic_details: "Techniques for improving communication with teenagers, addressing challenges, and fostering mutual respect." },
    { topic_title: "Child Behavior Management", topic_details: "Learn effective strategies for managing challenging behaviors in children, including positive reinforcement and behavior modification." },
    { topic_title: "Resume Building", topic_details: "Practical tips and strategies for building a strong resume that effectively showcases your skills and experience." },
    { topic_title: "Career Path Planning", topic_details: "Steps for mapping out a successful career path, considering your goals, strengths, and opportunities." },
    { topic_title: "Job Search Strategy", topic_details: "A comprehensive guide to job search strategies, including how to target the right roles and maximize your chances of success." },
    { topic_title: "Salary Negotiation", topic_details: "Learn how to negotiate your salary effectively, ensuring you're paid what you're worth based on your experience and industry standards." },
    { topic_title: "LinkedIn Optimization", topic_details: "Techniques for optimizing your LinkedIn profile to attract recruiters, showcase your skills, and expand your professional network." },
    { topic_title: "Industry Transitions", topic_details: "Strategies for making successful transitions between industries, including skill adaptation and networking." },
    { topic_title: "Remote Work Success", topic_details: "Tips for thriving in a remote work environment, from setting up your workspace to maintaining work-life balance." },
    { topic_title: "Professional Networking", topic_details: "The importance of professional networking and strategies to build valuable connections that benefit your career." },
    { topic_title: "Study Techniques", topic_details: "Proven study techniques to improve retention and understanding, from active recall to spaced repetition." },
    { topic_title: "Memory Improvement", topic_details: "Tips and exercises for improving memory and cognitive function, aiding in learning and information recall." },
    { topic_title: "Time Management", topic_details: "Effective time management strategies to optimize productivity and balance academic, personal, and professional commitments." },
    { topic_title: "Note-Taking Methods", topic_details: "Explore different note-taking techniques, including Cornell Notes and mind mapping, to enhance learning and retention." },
    { topic_title: "Learning Style Assessment", topic_details: "Learn about different learning styles and how to assess and cater to your personal learning preferences." },
    { topic_title: "Concentration Tips", topic_details: "Practical tips for improving concentration and staying focused during study sessions or work tasks." },
    { topic_title: "Exam Preparation", topic_details: "Effective methods for preparing for exams, including time management, practice tests, and stress reduction techniques." },
    { topic_title: "Self-Paced Learning", topic_details: "How to structure and succeed in self-paced learning environments, from setting goals to staying motivated." },
    { topic_title: "Language Learning Methods", topic_details: "An overview of various language learning methods, from immersive experiences to online tools and apps." },
    { topic_title: "Conversation Practice", topic_details: "Tips and techniques for practicing conversation in a new language to improve fluency and confidence." },
    { topic_title: "Grammar Mastery", topic_details: "Master the rules of grammar in your target language to enhance your ability to communicate clearly and correctly." },
    { topic_title: "Vocabulary Building", topic_details: "Strategies for building a strong vocabulary in a new language, including flashcards and context-based learning." },
    { topic_title: "Cultural Understanding", topic_details: "The importance of understanding the culture behind a language to communicate more effectively and meaningfully." },
    { topic_title: "Pronunciation Tips", topic_details: "Techniques for improving pronunciation and mastering difficult sounds in a new language." },
    { topic_title: "Language Immersion", topic_details: "The benefits of language immersion and how to integrate it into your learning process for faster results." },
    { topic_title: "Learning Resources", topic_details: "A guide to the best resources for learning a new language, from textbooks to online courses and mobile apps." },
    { topic_title: "Interview Preparation", topic_details: "How to prepare for an interview, including researching the company, practicing answers, and dressing appropriately." },
    { topic_title: "Common Questions & Answers", topic_details: "A guide to the most common interview questions and how to answer them effectively to make a strong impression." },
    { topic_title: "Body Language Tips", topic_details: "Learn how to use body language to convey confidence and professionalism during an interview." },
    { topic_title: "Virtual Interview Success", topic_details: "Strategies for succeeding in virtual interviews, including technical preparation and maintaining engagement." },
    { topic_title: "Technical Interview Prep", topic_details: "How to prepare for technical interviews, including coding challenges, problem-solving, and demonstrating your skills." },
    { topic_title: "Behavioral Interview Practice", topic_details: "Prepare for behavioral interview questions using the STAR method to highlight your achievements and problem-solving abilities." },
    { topic_title: "Follow-up Strategies", topic_details: "Learn how to follow up after an interview, including how to write a thank-you note and maintain professionalism." },
    { topic_title: "Confidence Building", topic_details: "Techniques for building self-confidence, especially in high-pressure situations like interviews or presentations." },
    { topic_title: "Goal Setting & Planning", topic_details: "Effective goal setting and planning strategies to achieve personal and professional success." },
    { topic_title: "Personal Finance", topic_details: "Fundamentals of managing your personal finances, including budgeting, saving, and investing." },
    { topic_title: "Work-Life Balance", topic_details: "Learn how to balance your career, personal life, and well-being to reduce stress and improve overall happiness." },
    { topic_title: "Decision Making", topic_details: "Techniques for making informed and confident decisions, both personally and professionally." },
    { topic_title: "Stress Management", topic_details: "Explore methods to manage and reduce stress, including mindfulness, time management, and relaxation techniques." },
    { topic_title: "Personal Growth", topic_details: "Strategies for fostering personal growth, including self-reflection, continuous learning, and embracing challenges." },
    { topic_title: "Habit Formation", topic_details: "Understand the science of habit formation and how to create positive habits that lead to long-term success." },
    { topic_title: "Life Transitions", topic_details: "Learn how to navigate significant life transitions, such as career changes or personal milestones, with confidence." },
    { topic_title: "Product Strategy Review", topic_details: "Review and refine your product strategy to ensure it aligns with market needs and company goals." },
    { topic_title: "Market Analysis", topic_details: "Conduct a comprehensive market analysis to understand trends, customer needs, and opportunities for growth." },
    { topic_title: "Business Plan Feedback", topic_details: "How to gather and implement feedback on your business plan to increase its chances of success." },
    { topic_title: "Competitor Research", topic_details: "Learn how to conduct competitor research to gain valuable insights into market trends and identify gaps in the industry." },
    { topic_title: "Brand Positioning", topic_details: "Techniques for positioning your brand effectively in the market to attract and retain customers." },
    { topic_title: "Creative Writing Ideas", topic_details: "Brainstorm creative writing prompts and techniques to inspire your next writing project." },
    { topic_title: "Content Strategy", topic_details: "Develop a content strategy that aligns with your brand's goals, from blogs to social media posts." },
    { topic_title: "Marketing Campaign Brainstorm", topic_details: "Generate creative ideas for your next marketing campaign, from product launches to seasonal promotions." },
    { topic_title: "Design Feedback", topic_details: "How to receive and give constructive design feedback to improve the quality of your projects." },
    { topic_title: "Innovation Workshop", topic_details: "How to run an innovation workshop that fosters creativity and generates new ideas for your business." },
    { topic_title: "Career Planning", topic_details: "Plan for your future career by setting clear goals, networking, and developing skills for long-term success." },
    { topic_title: "Leadership Skills", topic_details: "Learn essential leadership skills, from communication to decision-making, that inspire and motivate teams." },
    { topic_title: "Public Speaking Tips", topic_details: "Tips for becoming a more confident and effective public speaker, whether in small meetings or large audiences." },
    { topic_title: "Interview Preparation", topic_details: "Thorough preparation for interviews, including how to research the company, anticipate questions, and present your best self." },
    { topic_title: "Professional Growth", topic_details: "Strategies for continuous professional development, including networking, education, and self-improvement." },
    { topic_title: "Industry Trends Analysis", topic_details: "How to analyze industry trends and use them to drive innovation and business growth." },
    { topic_title: "Data Interpretation", topic_details: "Techniques for interpreting complex data to make informed business decisions." },
    { topic_title: "Research Planning", topic_details: "A guide to planning research projects, from developing hypotheses to collecting and analyzing data." },
    { topic_title: "Survey Analysis", topic_details: "Learn how to analyze survey results to derive actionable insights for decision-making." },
    { topic_title: "Academic Paper Review", topic_details: "How to critically review academic papers and research to assess their validity and relevance." },
    { topic_title: "Youtube Video ideas", topic_details: "Creative ideas for engaging and valuable Youtube videos, including trending topics and evergreen content." },
    { topic_title: "Superfoods and Their Benefits", topic_details: "Explore the health benefits of various superfoods and how to incorporate them into your diet." },
    { topic_title: "Fitness Routines", topic_details: "Effective fitness routines for different goals, from weight loss to building muscle and improving endurance." },
    { topic_title: "Travel Planning for Groups", topic_details: "Tips for planning group travel, including accommodation, activities, and managing different preferences." },
    { topic_title: "Tourism Marketing Strategies", topic_details: "Marketing strategies for the tourism industry to attract visitors and increase engagement." },
    { topic_title: "Event Planning Best Practices", topic_details: "Best practices for planning successful events, from logistics to guest engagement." },
    { topic_title: "Virtual Event Management", topic_details: "Techniques for managing virtual events, ensuring smooth operations and interactive experiences." },
    { topic_title: "Personal Brand Online Building", topic_details: "Strategies for building and maintaining a strong personal brand online through social media and content creation." },
    { topic_title: "Meal Prep Ideas", topic_details: "Creative and healthy meal prep ideas for easy, nutritious meals throughout the week." },
    { topic_title: "Plant-Based Diets", topic_details: "Explore the benefits of plant-based diets and tips for transitioning to a vegan or vegetarian lifestyle." },
    { topic_title: "Nutrition for Weight Loss", topic_details: "Essential nutrition tips for effective weight loss, including portion control and healthy eating habits." }
  ];

  const randomTopic = ()=>{
    const index = Math.floor(Math.random() * topics.length);
    const selectedTopic = topics[index];

    if (selectedTopic) {
      localStorage.setItem("topic", selectedTopic.topic_title);
    }else{
      localStorage.setItem("topic", 'Startup Strategy Planning');
    }
  }

  useEffect(()=>{
    if(searchTerm.length < 3){
      setSearchResults([]);
      return;
    }

    
    if(searchType === 'topic'){
      const allTopic = [...generalTopic , ...recommendedTopic, ...topics]
      const filterTopic = allTopic?.filter((topic) => topic?.topic_title?.toLowerCase().includes(searchTerm?.toLowerCase())).slice(0, 5);
      setSearchResults([...filterTopic,  {topic_title: searchTerm}]);
  
    }else if(searchType === 'persona'){
      const filterPersona = allPersonas?.filter((person) => person?.name?.toLowerCase().includes(searchTerm?.toLowerCase())).slice(0, 5);
      setSearchResults([...filterPersona, {name: searchTerm}]);
      
    }else{
      const allTopic = [...generalTopic, ...recommendedTopic, ...topics];
      const filterTopic = allTopic?.filter((topic) =>
        topic?.topic_title?.toLowerCase().includes(searchTerm?.toLowerCase())
      );

      const filterPersona = allPersonas?.filter((person) =>
        person?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );

      const combinedResults = [
        ...filterTopic.slice(0, 5),
        ...filterPersona.slice(0, 5)
      ];

      setSearchResults([...combinedResults, {topic_title: searchTerm}]);
    }
  },[searchTerm, searchType])

  const createNewPersona = async (title) => {
    try {
      const payload = {
        userid: userId ? userId : "",
        topic: title,
        number_of_persona: 1,
        diversity: "false",
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
        payload
      );
  
      const personasData = response.data.personas;
      setPersonas(personasData);
      localStorage.setItem("personas", JSON.stringify(personasData));
  
      // Only proceed to the second API call if the first one is successful
      if (userId) {
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
            { 
              userid: userId, 
              persona_name: personasData[0]?.name, 
              persona_description: personasData[0]?.description, 
              image: "../assetsNew/image/user.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
              isFavourite: "0" 
            }
          );
        } catch (error) {
          console.error("Error generating persona:", error);
          // You can handle persona generation error here, but the flow will continue
        }
      }
    } catch (error) {
      console.error("Error fetching personas:", error);
      // If there's an error in fetching personas, handle it here, but don't break the flow
    }
  };
  
  const handleOpenMeeting = async (result) => {
    if(isLoading){
      return;
    }

    let newResult;
    
    setSelectedItem(result?.name || result?.topic_title);
    let type = result?.name ? 'persona' : 'topic';

    //handle 'doctor' specific data
    if(result?.name?.toLowerCase() === 'doctor' || result?.topic_title?.toLowerCase() === 'doctor'){
      type = 'persona';
      newResult={name : result?.topic_title || result?.name}
    }

    //check it is a topic or a persona
    if(!result?.persona_description && !result?.topic_details && result?.name !== 'doctor' && result?.topic_title !== 'doctor'){
      try{
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/persona/search-identifier`,{
          data: result?.topic_title || result?.name
        })
        // console.log("user input topic response22", response)

        if(response?.data?.classification === 'topic'){
          type = 'topic';
        }else if(response?.data?.classification === 'character'){
          type = 'persona';
          newResult = { name : result?.topic_title};
        }else{
          toast.error("Invalid Input, Please enter a valid topic or parsona name");
          setIsLoading(false);
          return;
        }

      }catch (error) {
        toast.error("Invalid Input, Please enter a valid topic or parsona name");
        setIsLoading(false);
        return;
      }
    }



    if (type === 'persona') {

      const isExist = personas?.some((person) => {
        const nameToCheck = result?.name || newResult?.name;
        return person?.name === nameToCheck;
      });
      
  
      if (isExist) {
        toast.error("Persona already exists.");
        return;
      } else {
        //logic of genarate new persona accoding to user input
        
        const newPersona = {
          name: result?.name || newResult?.name,
          description: result?.persona_description ? result?.persona_description : `${result?.name || newResult?.name} is a newly generated persona for you to discuss your topics`,
          image: result?.image ? result?.image : "./businessman9.png"
        }
        
        setPersonas([newPersona]);
        // toast.success("Now Select some topic to continue.");

        if (userId) {
          setIsLoading(true);
          
          try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/persona/generate_persona`, { 
              userid: userId, 
              persona_name: result?.name || newResult?.name, 
              persona_description: result?.persona_description ? result?.persona_description : `${result?.name || newResult?.name} is a newly generated persona for you to discuss your topics`, 
              image: "./businessman9.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
              isFavourite: "0"
            });
    
          } catch (error) {
            // toast.error("Error generating persona.");
            setIsLoading(false);
          }
        }
        //add random topic
        randomTopic();
        
        setIsLoading(false);
        navigate('/meeting');
      }
  
    } else {
      setIsLoading(true);
      
      // if(result?.topic_title === searchTerm && !result?.topic_details){
      //   try{
      //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/topic/topic_checking`,{
      //       topic: result?.topic_title,
      //       userid: userId ? userId : ""
      //     })
      //     console.log("user input topic response", response)

      //     if(response.data === 'YES'){
      //       localStorage.setItem("topic", result?.topic_title);
      //       // navigate('/meeting');
      //     }else{
      //       if(response.Error === 'Topic already exists , Check models.'){
      //         console.log("same topic")
      //       }else{
      //         toast.error("Invalid Topic, Please enter a valid topic name");
      //         setIsLoading(false);
      //         return;
      //       }
      //     }

      //   }catch (error) {
      //     toast.error("Invalid Topic, Please enter a valid topic name");
      //     setIsLoading(false);
      //     return;
      //   }
      // }

      await createNewPersona(result?.topic_title);
      setIsLoading(false);
      localStorage.setItem("topic", result?.topic_title);
      navigate('/meeting');
    }
  }
  

        
  return (
    <>
      {/* search box */}
      <div className="container my-lg-4 mt-sm-2 pt-2 position-relative">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8 search-input">
            <input
              type="text"
              placeholder="Search for discussion"
              className="form-control rounded-5 ps-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <img src={SearchIcon} alt="search icon" width={26} />
          </div>
        </div>
        {searchResults?.length > 0 && (
        <div className="search-input-results" id="search-results">
          <div className="neon-effect search-result">
            {searchResults && searchResults?.map((result, index)=>(
              <div key={index} className="input-result-card" onClick={()=>handleOpenMeeting(result)}>
                <div className="result-heder">
                  {isLoading && selectedItem === (result?.name || result?.topic_title) ? <h2>Loading ...</h2> : <h2>{result?.name || result?.topic_title}</h2> }
                  {!result?.persona_description && !result?.topic_details ? <p># persona / topic</p> : <p>#{result?.name ? ' persona' : ' topic'}</p>}
                </div>
                {
                  isLoading && selectedItem === (result?.name || result?.topic_title) ? <p>Loading ...</p> : <p>{result?.persona_description || result?.topic_details || `Click to begin discuss with ${result?.name || result?.topic_title}`}</p>
                }
                {/* {isLoading ? <p>Loading ...</p> : <p>{result?.persona_description || result?.topic_details || `Click to begin discuss with ${result?.name || result?.topic_title}`}</p>} */}
              </div>
            ))}
          </div>
        </div>
        )}
      </div>
    </>
  );
}

export default SearchBox;
