import React, { useContext, useEffect, useState } from "react";
import businessmanPic9 from "../../assetsNew/image/businessman/businessman9.png";
import { AppContext } from "../../Context/AppContext";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Category() {
  const { topicCategory = [], setPersonas } = useContext(AppContext);
  const profileData = secureLocalStorage.getItem("profileData");
  const userId = secureLocalStorage.getItem("userId");
  const usertype = secureLocalStorage.getItem("usertype");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const createNewPersona = async (title) => {
    try {
      const payload = {
        userid: userId ? userId : "",
        topic: title,
        number_of_persona: 1,
        diversity: "false",
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
        payload
      );
  
      const personasData = response.data.personas;
      setPersonas(personasData);
      localStorage.setItem("personas", JSON.stringify(personasData));
  
      // Only proceed to the second API call if the first one is successful
      if (userId) {
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
            { 
              userid: userId, 
              persona_name: personasData[0]?.name, 
              persona_description: personasData[0]?.description, 
              image: "../assetsNew/image/user.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
              isFavourite: "0" 
            }
          );
        } catch (error) {
          console.error("Error generating persona:", error);
          // You can handle persona generation error here, but the flow will continue
        }
      }
    } catch (error) {
      console.error("Error fetching personas:", error);
      // If there's an error in fetching personas, handle it here, but don't break the flow
    }
  };
  
  const handleMeetingPage = async (title) => {
    if(isLoading){
      return;
    }
    
    try {
      setSelectedItem(title);
      setIsLoading(true);
      await createNewPersona(title);
      setIsLoading(false);
      localStorage.setItem("topic", title); 
      navigate('/meeting');
    } catch (error) {
      console.error("Error in handling meeting page:", error);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState(topicCategory[0] || []);
  const [selectedId, setSelectedId] = useState(topicCategory[0]?.id);

  useEffect(()=>{
    const filterCatagory = ()=>{
      const filterData = topicCategory.filter((cat) => cat.id === selectedId);
      setSelectedCategory(filterData[0]?.topic_details);
      // console.log("filter", filterData[0]?.topic_details);
    }
    filterCatagory();
  },[selectedId])

  useEffect(()=>{
    setSelectedId(topicCategory[0]?.id);
  },[topicCategory]);

  useEffect(() => {
    // Handle scrollable banner logic
    const scrollableBanner = document.querySelector(".scrollable-banner2");
    let isDragging = false;
    let startPos = 0;
    let scrollLeft = 0;

    if (scrollableBanner) {
      scrollableBanner.addEventListener("mousedown", (e) => {
        isDragging = true;
        startPos = e.pageX - scrollableBanner.offsetLeft;
        scrollLeft = scrollableBanner.scrollLeft;
        scrollableBanner.style.cursor = "grabbing";
        e.preventDefault();
      });

      scrollableBanner.addEventListener("mouseleave", () => {
        isDragging = false;
      });

      scrollableBanner.addEventListener("mouseup", () => {
        isDragging = false;
      });

      scrollableBanner.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollableBanner.offsetLeft;
        const walk = (x - startPos) * 1;
        scrollableBanner.scrollLeft = scrollLeft - walk;
      });
    } else {
      console.error("Scrollable banner element not found!");
    }
  }, []);

  useEffect(() => {
    // Handle scrollable banner logic
    const scrollableBanner = document.querySelector(".scrollable-banner3");
    let isDragging = false;
    let startPos = 0;
    let scrollLeft = 0;

    if (scrollableBanner) {
      scrollableBanner.addEventListener("mousedown", (e) => {
        isDragging = true;
        startPos = e.pageX - scrollableBanner.offsetLeft;
        scrollLeft = scrollableBanner.scrollLeft;
        scrollableBanner.style.cursor = "grabbing";
        e.preventDefault();
      });

      scrollableBanner.addEventListener("mouseleave", () => {
        isDragging = false;
      });

      scrollableBanner.addEventListener("mouseup", () => {
        isDragging = false;
      });

      scrollableBanner.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollableBanner.offsetLeft;
        const walk = (x - startPos) * 1;
        scrollableBanner.scrollLeft = scrollLeft - walk;
      });
    } else {
      console.error("Scrollable banner element not found!");
    }
  }, []);

  return (
    <>
      {/* General Topics */}
      <div className="container py-5 position-relative text-white">
        <div className="scrollable-banner2">
            <div className="row flex-nowrap">
                {topicCategory && topicCategory.map((topic)=>(
                    <div className={selectedId === topic?.id ? 'col category-tab-btn active-btn' : 'col category-tab-btn' } key={topic?.id} onClick={()=>setSelectedId(topic?.id)}>
                        <p>{topic?.name}</p>
                    </div>
                ))}
            </div>
        </div>
        <div className="scrollable-banner3">
          <div className="row flex-nowrap">
            {selectedCategory?.length > 0 && selectedCategory?.map((card, index) => (
              <div className="col" key={index}>
                <div className="recommended-card marginNegative ">
                  <img onClick={()=>handleMeetingPage(card?.name)} className="reco-card-image mb-3" src={card?.image || businessmanPic9} alt="" />
                  <div className="main-info">
                    <p onClick={()=>handleMeetingPage(card?.name)}>{isLoading && selectedItem === card?.name ? 'Loading ...' : card?.name}</p>
                    <p>@Slara.ai</p>
                  </div>
                </div>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
