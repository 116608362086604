import React from 'react'
import Navbar from '../../sharedNew/navbar'
import PricingPlan from '../../components/home/pricingPlan'
import Footer from '../../sharedNew/footer'

function Pricing() {
  const NavButtons = [{
    path: "/meeting", title: "GO TO DASHBOARD"
  }]

  return (
  <>
    <Navbar NavButtons={NavButtons}/>
    <PricingPlan/>
    <Footer/>
  </>
  )
}

export default Pricing