/* eslint-disable no-unused-vars */
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LandingPage from "./pages/landingPage";
import ContactUs from "./pages/contactUs";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import Forgetpassword from "./pages/passRecover";
import VerifyCode from "./pages/verifyCode";
import ResetPswd from "./pages/setNewPass";
import PricingPage from "./pages/pricingPage";
import Dashboard from "./dashboard/dashboard";
import Dashboardoutlet from "./dashboard/dashboardoutlet";
import Dashboardmain from "./dashboard/DashboardMain/dashboardmain";
import Conversations from "./dashboard/Conversations/conversations";
import Modelmian from "./dashboard/Models/modelsMain";
import Models from "./dashboard/Models/models";
import Settings from "./dashboard/Settings/settings";
import EditPersona from "./dashboard/DashboardMain/Personas/editPersona";
import PersonaCreated from "./dashboard/DashboardMain/Personas/personaCreated";
import Discussion from "./dashboard/DashboardMain/Discussion/discussion";
import Feedback from "./dashboard/DashboardMain/Feedback/feedback";
import Updatepswd from "./dashboard/Settings/updatepswd";
import EditProfile from "./dashboard/Settings/editProfile";
import UpdateEmail from "./dashboard/Settings/updateEmail";
import VerifyEmail from "./dashboard/Settings/verifyEmail";
import SettingMain from "./dashboard/Settings/settingMain";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsServices from "./pages/termsServices";
import ModelsDiscussion from "./dashboard/Models/modelsDiscussion";
import ConversationMain from "./dashboard/Conversations/conversationMain";
import ConversationDiscussion from "./dashboard/Conversations/conversationDiscussion";
import PaymentPending from "./pages/pendingPayment";
import { AppProvider } from "./Context/AppContext";
import Auth from "./Auth";
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./privateRoute";
import Plans from "./dashboard/Plans";
import ThankYou from "./pages/thankYou";
import BlogPage from "./pages/blogPage";
import MainBlogsPage from "./pages/MainBlogsPage";
import MeetingPage from "./pages/meetingPage";
import Pricing from "./componentsNew/pricing/Pricing";
import ShareLink from "./componentsNew/share/ShareLink";

const AppLayout = () => {
  return (
    <>
      <ToastContainer />
      <AppProvider>
        <RouterProvider router={appRouter} />
      </AppProvider>
    </>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <GuestRoute>
        <LandingPage />
      </GuestRoute>
    ),
  },
  {
    path: "/meeting",
    element: <MeetingPage />,
  },
  {
    path: "/share/:topic",
    element: <ShareLink />,
  },
  {
    path: "/pricing-plans",
    element: <Pricing />,
  },
  {
    path: "/thank-you",
    element: <ThankYou />,
  },
  {
    path: "/pending-payment",
    element: (
      <Auth>
        <PaymentPending />
      </Auth>
    ),
  },
  {
    path: "/signin",
    element: (
      <GuestRoute>
        {" "}
        <SignIn />
      </GuestRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <GuestRoute>
        <SignUp />
      </GuestRoute>
    ),
  },
  {
    path: "/forgetpassword",
    element: (
      <GuestRoute>
        <Forgetpassword />
      </GuestRoute>
    ),
  },
  {
    path: "/verify-code",
    element: (
      <GuestRoute>
        <VerifyCode />
      </GuestRoute>
    ),
  },
  {
    path: "/resetpassword",
    element: (
      <GuestRoute>
        <ResetPswd />
      </GuestRoute>
    ),
  },

  {
    path: "/settings",
    element: <SettingMain />,
    children: [
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "update-password",
        element: <Updatepswd />,
      },
      {
        path: "edit-profile",
        element: <EditProfile />,
      },
      {
        path: "update-Email",
        element: <UpdateEmail />,
      },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      },
    ],
  },
    
]);

export default AppLayout;
