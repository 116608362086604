import React, { useContext, useEffect, useState } from "react";
import businessmanPic9 from "../../assetsNew/image/businessman/businessman9.png";
import leftArrow from "../../assetsNew/image/left-arrow.png";
import rightArrow from "../../assetsNew/image/right-arrow.png";
import { AppContext } from "../../Context/AppContext";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function General() {
  const { generalTopic, setPersonas } = useContext(AppContext);
  const profileData = secureLocalStorage.getItem("profileData");
  const userId = secureLocalStorage.getItem("userId");
  const usertype = secureLocalStorage.getItem("usertype");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  
  const createNewPersona = async (title) => {
    try {
      const payload = {
        userid: userId ? userId : "",
        topic: title,
        number_of_persona: 1,
        diversity: "false",
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
        payload
      );
  
      const personasData = response.data.personas;
      setPersonas(personasData);
      localStorage.setItem("personas", JSON.stringify(personasData));
  
      // Only proceed to the second API call if the first one is successful
      if (userId) {
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`,
            { 
              userid: userId, 
              persona_name: personasData[0]?.name, 
              persona_description: personasData[0]?.description, 
              image: "../assetsNew/image/user.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
              isFavourite: "0" 
            }
          );
        } catch (error) {
          console.error("Error generating persona:", error);
          // You can handle persona generation error here, but the flow will continue
        }
      }
    } catch (error) {
      console.error("Error fetching personas:", error);
      // If there's an error in fetching personas, handle it here, but don't break the flow
    }
  };
  
  const handleMeetingPage = async (title) => {
    if(isLoading){
      return;
    }

    try {
      setSelectedItem(title);
      setIsLoading(true);
      await createNewPersona(title);
      setIsLoading(false);
      localStorage.setItem("topic", title); 
      navigate('/meeting');
    } catch (error) {
      console.error("Error in handling meeting page:", error);
    }
  };

  useEffect(() => {
    // Handle scrollable banner logic
    const scrollableBanner = document.querySelector(".scrollable-banner");
    let isDragging = false;
    let startPos = 0;
    let scrollLeft = 0;

    if (scrollableBanner) {
      scrollableBanner.addEventListener("mousedown", (e) => {
        isDragging = true;
        startPos = e.pageX - scrollableBanner.offsetLeft;
        scrollLeft = scrollableBanner.scrollLeft;
        scrollableBanner.style.cursor = "grabbing";
        e.preventDefault();
      });

      scrollableBanner.addEventListener("mouseleave", () => {
        isDragging = false;
        scrollableBanner.style.cursor = "grab";
      });

      scrollableBanner.addEventListener("mouseup", () => {
        isDragging = false;
        scrollableBanner.style.cursor = "grab";
      });

      scrollableBanner.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollableBanner.offsetLeft;
        const walk = (x - startPos) * 1;
        scrollableBanner.scrollLeft = scrollLeft - walk;
      });
    } else {
      console.error("Scrollable banner element not found!");
    }
  }, []);

  // Card Slider Logic
  function scrollBannerLeft() {
    const banner = document.querySelector('.scrollable-banner'); 
    if (banner) {
        banner.scrollBy({
            left: -370,
            behavior: 'smooth',
        });
    } else {
        console.error("Scrollable banner element not found!");
    }
  }

  function scrollBannerRight() {
    const banner = document.querySelector('.scrollable-banner'); 
    if (banner) {
        banner.scrollBy({
            left: 370,
            behavior: 'smooth',
        });
    } else {
        console.error("Scrollable banner element not found!");
    }
  }

  return (
    <>
      {/* General Topics */}
      <div className="container py-5 position-relative text-white">
        <h4 className="text-white">General Topics</h4>
        <div className="scrollable-banner">
          <div className="row flex-nowrap">
            {generalTopic && generalTopic.map((card, index) => (
              <div className="col" key={index}>
                <div className="recommended-card marginNegative">
                  <img onClick={()=>handleMeetingPage(card?.topic_title)} className="reco-card-image" src={card?.image || businessmanPic9} alt="" />
                  <div className="main-info">
                    <p 
                      onClick={()=>handleMeetingPage(card?.topic_title)}>{isLoading && selectedItem === card?.topic_title ? 'Loading ...' : card?.topic_title}</p>
                    <p 
                      className={isLoading && selectedItem === card?.topic_title ? 'pb-3' : ''}
                      onClick={()=>handleMeetingPage(card?.topic_title)}>{isLoading && selectedItem === card?.topic_title ? 'Loading' : card?.topic_details.slice(0,60)} ...</p>
                  </div>
                </div>
              </div>
            ))}
            
          </div>
        </div>
        <div className="container mt-4 d-flex justify-content-center gap-5 direction-arrow">
          <img src={leftArrow} alt="left-arrow" onClick={scrollBannerLeft} />
          <img
            src={rightArrow}
            alt="right-arrow"
            onClick={scrollBannerRight}
          />
        </div>
      </div>
    </>
  );
}

export default General;
