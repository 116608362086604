import React, { useContext, useEffect, useState } from 'react';
import starLogo from '../assetsNew/image/starlogo.png'; 
import { AppContext } from '../Context/AppContext';
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';

const FavoritePersonaPopup = ({personaOne, handleLikePopUpClose, likePopUp}) => {
  const { personas, setFetchedPersonas, setPersonas } = useContext(AppContext);
  const userId = secureLocalStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false)

  // console.log("Selected persona in liked", personaOne);

  const handleLikePerson = async () => {
    setIsLoading(true);
    try {
      // First API call
      const updateResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/update_favourite`,
        {
          userid: userId,
          persona_name: personaOne?.name,
        }
      );
      console.log("Update Response:", updateResponse);
      // toast.success(`Persona ${personaOne?.name} added to Favourites successfully`);
  
      // Second API call
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/get_all_persona`,
        {
          userid: userId,
        }
      );
      const allPersonasData = response.data;
      console.log("All Personas Data:", allPersonasData);
  
      if (allPersonasData?.length > 0) {
        const updatedPersonas = personas.map((p) => {
          const match = allPersonasData.find((a) => a.name === p.name);
          return match
            ? { ...p, isFavourite: match.isFavourite === 1 ? 1 : 0 }
            : p;
        });
  
        console.log("Updated Personas:", updatedPersonas);
  
        setPersonas(updatedPersonas);
        localStorage.setItem("personas", JSON.stringify(updatedPersonas));
      }

      // toast.success(`Persona ${personaOne?.name} added to Favourites successfully`);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // toast.error("Issue updating persona or fetching data");
      setIsLoading(false);
    }finally {
      // Close Popup
      handleLikePopUpClose();
    }
  };
  

  return (
    <Modal 
      show={likePopUp} 
      onHide={handleLikePopUpClose}
      centered
      contentClassName="modal-content custom-modal persona-model-popups"
    >
          <i
            className="fa-sharp fa-solid fa-circle-xmark"
            onClick={handleLikePopUpClose}
          ></i>
          <img src={starLogo} alt="starlogo" />
          <h2>
            Want this persona to <br />
            {personaOne?.isFavourite && personaOne?.isFavourite === 1 ? 'remove from ' : 'add in ' }<span> Favorites</span>?
          </h2>
          <div className="mt-3 my-2 d-flex gap-3 justify-content-center align-items-center">
            <button 
              className="first-button"
              onClick={handleLikePopUpClose}
            >Cancel</button>
            <button 
              className="second-button"
              onClick={handleLikePerson}
              disabled={isLoading}
            >{isLoading ? "Loading ..." : "Save"}</button>
          </div>
    </Modal>
  );
};

export default React.memo(FavoritePersonaPopup);
