import React, { useContext, useEffect } from "react";
import PricingPlan from "../components/home/pricingPlan";

import Navbar from "../sharedNew/navbar";
import MainCard from "../componentsNew/home/MainCard";
import General from "../componentsNew/home/General";
import SearchBox from "../componentsNew/home/SearchBox";
import Recommended from "../componentsNew/home/Recommended";
import Footer from "../sharedNew/footer";
import { AppContext } from "../Context/AppContext";
import Category from "../componentsNew/home/Category";
import secureLocalStorage from "react-secure-storage";

const LandingPage = () => {
const {
  setRecommendedTopic,
  setGeneralTopic,
  setTopicCategory,
  setSearchType,
  setAllPersonas,
  setUserHasPlan,
  userHasPlan
} = useContext(AppContext);

const userprofileData = secureLocalStorage.getItem("profileData");
const userId = secureLocalStorage.getItem("userId");

//handle for selected card fade-out fade-in animation 
useEffect(() => {
    const CrossBtn = document.querySelector(".cross-button");
    const mainCard = document.querySelector(".main-card");
    const leftCard = document.querySelector(".left-card");
    const ArrowLine = document.querySelector(".arrow-line");
    const middleCard = document.querySelector("#middle-card-id");
    const rightCard = document.querySelector("#right-card-id");
    const inputBox = document.querySelector(".search-input input");
    const inputBoxResult = document.querySelector("#search-results");
    const VectorSvg = document.querySelector("#vector-svg");
    const allElements = document.querySelectorAll(
        ".main-card .row > * , .main-text, .text-white, .recommended-card, .footer, .direction-arrow, .logo, .header-vector, .header-btn"
    );

    const handleClick = (clickedCard, otherCard, ChType) => {
        allElements.forEach((element) => {
            if (element !== clickedCard && element !== inputBox) {
                element.classList.add("fade-out");
            } else {
                element.classList.remove("fade-out");
            }
        });
        otherCard.classList.add("fade-out");

        mainCard.classList.add("resize-maincard");
        middleCard.classList.add("mRight");

        CrossBtn.classList.remove("display-none");
        VectorSvg.classList.add("display-none");

        leftCard.classList.add("display-none");
        ArrowLine.classList.add("display-none");
        // inputBoxResult.classList.remove("display-none");

        middleCard.classList.add("neon-effect");
        rightCard.classList.add("neon-effect");

        // setSearchType(ChType);
    };

    const closeFunctionality = () => {
        allElements.forEach((element) => {
            element.classList.remove("fade-out");
        });
        mainCard.classList.remove("resize-maincard");
        middleCard.classList.remove("mRight");

        CrossBtn.classList.add("display-none");
        VectorSvg.classList.remove("display-none");

        leftCard.classList.remove("display-none");
        ArrowLine.classList.remove("display-none");
        // inputBoxResult.classList.add("display-none");

        middleCard.classList.remove("neon-effect");
        rightCard.classList.remove("neon-effect");
        // setSearchType('');
    };

    if (middleCard && rightCard) {
        middleCard.addEventListener("click", () => handleClick(middleCard, rightCard, 'topic'));
        rightCard.addEventListener("click", () => handleClick(rightCard, middleCard, 'persona'));
    }

    if (CrossBtn) {
        CrossBtn.addEventListener("click", closeFunctionality);
    }

    // Add event listener to handle clicks on the body
    document.addEventListener("click", (event) => {
        // Check if the clicked element is part of the faded-out elements
        const isFaded = Array.from(allElements).some((element) =>
            element.contains(event.target)
        );

        const isInputBox = inputBox && inputBox.contains(event.target);
        const isInputBoxResult = inputBoxResult && inputBoxResult.contains(event.target);


        // If clicked outside the faded elements, close functionality
        // if (!isFaded && !isInputBox && !isInputBoxResult) {
        //     closeFunctionality();
        // }
    });

    // Cleanup event listeners on unmount
    return () => {
        if (middleCard && rightCard) {
            middleCard.removeEventListener("click", () => handleClick(middleCard, rightCard));
            rightCard.removeEventListener("click", () => handleClick(rightCard, middleCard));
        }
        if (CrossBtn) {
            CrossBtn.removeEventListener("click", closeFunctionality);
        }
        document.removeEventListener("click", closeFunctionality);
    };
}, []);

//Fetch recommended & general topic
useEffect(()=>{
  const fetchTopic = async() => {
    try {
        const fetchRecommendedTopics = await fetch(`${process.env.REACT_APP_BASE_URL}/persona/get_recommended_topic`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          }
        });
        const fetchGeneralTopics = await fetch(`${process.env.REACT_APP_BASE_URL}/persona/get_general_topic`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          }
        });

        const result1 = await fetchRecommendedTopics.json();
        const result2 = await fetchGeneralTopics.json();


        setRecommendedTopic(result1?.response);
        setGeneralTopic(result2?.response);

        // console.log("recommended topic :", result1.response)
        // console.log("general topic :", result2.response)
        
    } catch (error) {
      console.log('Error from Fetch Topics: ',error);
    }
  }
  fetchTopic();

},[])

//Fetch get_categories
useEffect(()=>{
  const handlefetchCategory = async() => {
    try {
        const fetchCategory = await fetch(`${process.env.REACT_APP_BASE_URL}/persona/get_categories`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          }
        });

        const result = await fetchCategory.json();
        setTopicCategory(result?.response);

        // console.log("category :", result.response)
    } catch (error) {
      console.log('Error from Fetch Category: ',error);
    }
  }
  handlefetchCategory();

},[])

// useEffect(()=>{
//   console.log("userHasPlan", userHasPlan);
//   console.log("userprofileData", userprofileData);
// },[userId])

//Fetch all Personas
useEffect(()=>{
  const handlefetchAllPersona = async() => {
    try {
        const fetchPersonasall = await fetch(`${process.env.REACT_APP_BASE_URL}/persona/get_persona`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          }
        });

        const result = await fetchPersonasall.json();
        setAllPersonas(result?.Response);

        // console.log("all persona :", result.Response)
    } catch (error) {
      console.log('Error from Fetch persona: ',error);
    }
  }
  handlefetchAllPersona();

},[])


  return (
    <>
      <Navbar />
      <MainCard />
      <SearchBox />
      <Recommended />
      <Category />
      <General />
      <PricingPlan />
      <Footer />
    </>
  );
};

export default LandingPage;
