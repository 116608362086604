import React, { useContext, useEffect, useState } from "react";
import "../../style/components/pricingPlan.css";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";

const PricingPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPlan, setSelectedPlan] = useState("individual");
  const userId = secureLocalStorage.getItem("userId");
  const profileData = secureLocalStorage.getItem("profileData");

  const {
    userHasPlan,
    setUserHasPlan,
    individualPlans,
    enterprisePlans,
    textBasedPlans,
    ProfileData,
  } = useContext(AppContext);
  useEffect(() => {
    ProfileData();
  }, []);

  // console.log("userHasPlan", userHasPlan);
  // console.log("profileData", profileData);
  const baseUrl = window.location.origin;

  const handleGetStarted = async (name, price) => {
    // window.dataLayer.push({
    //   event: 'plan_selected',
    //   planName: name,
    //   planPrice: parseFloat(price),
    // });
    if (name === "Free_Trial" && userId) {
      try {
        const payload = profileData?.email
          ? {
              userid: userId || "",
              amount: price,
              plan_name: name,
              email: profileData.email,
              base_url: baseUrl || "http://localhost:3000",
            }
          : {
              userid: "",
              amount: 0.0,
              plan_name: name,
              email: "abc@gmail.com",
              base_url: "",
            };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
          payload
        );
        if (response.data.save === "save plan") {
          setUserHasPlan("Free_Trial");
          toast.success("Free trial activated!");
          await ProfileData();
          navigate("/");
        } else if (response.data["Already subscribed"]) {
          toast.error(
            response.data["Already subscribed"] ||
              "You have already used your free trial!"
          );
        } else if (response.data.Response) {
          const { userid, token, plan, guest_id } = response.data.Response;

          secureLocalStorage.setItem("authToken", token);
          secureLocalStorage.setItem("userId", userid);
          secureLocalStorage.setItem("guestId", guest_id);
          secureLocalStorage.setItem("usertype", "guest");
          setUserHasPlan(plan);
          toast.success("Free Trial Activated! ");
          navigate("/");
          await ProfileData();
        } else {
          toast.error("You already have a plan");
        }
      } catch (error) {
        console.error("Error activating free trial:", error);
        // toast.error("Something went wrong. Please try again later.");
      }
    } else {
      if (userId) {
        if (userHasPlan?.plan_name) {
          if(profileData?.email === "abc@gmail.com"){
            navigate("/signin");
          }else{
            // toast.info("You already have an active plan.");
            upgradePlan(name, price);
          }
        } else {
          // window.dataLayer.push({
          //   event: 'plan_selected',
          //   planName: name,
          //   planPrice: parseFloat(price),
          // });
          makePayment(name, price);
        }
      } else {
        navigate("/signup");
      }
    }
  };

  const upgradePlan = async (name, price) => {
    try {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upgrade_plan`,
        {
          userid: userId,
          amount: price,
          plan_name: name,
          email: profileData?.email,
          base_url: baseUrl || "http://localhost:3000",
        }
      );

      const session = response.data.sessionId;
      if (response.data === "You have already a plan") {
        toast.info("You already have an active plan.");
        return;
      }

      const result = stripe.redirectToCheckout({
        sessionId: session,
      });
      if (result.error) {
        // Handle error here
      }
    } catch (e) {
      // console.log("Error upgrading plan:", e.response.data);
      toast.error(e.response.data || "You already have a plan!");
    }
  };
  const makePayment = async (name, price) => {
    try {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
        {
          userid: userId,
          amount: price,
          plan_name: name,
          email: profileData?.email,
          base_url: baseUrl || "http://localhost:3000",
        }
      );

      const session = response.data.sessionId;
      if (response.data === "You have already a plan") {
        toast.info("You already have an active plan.");
        return;
      }

      const result = stripe.redirectToCheckout({
        sessionId: session,
      });
      if (result.error) {
        // Handle error here
        console.log("error1", result.error);
      }
    } catch (e) {
      console.log("error22", e);
      toast.error(e?.response?.data || "You already have a plan!");
    }
  };

  return (
    <div className="pricingSingleCard" id="pricing">
      <div className="container">
        <h2 className="fw-semibold text-white h-center text-center">Individual Pricing Plan</h2>
        <p className="max-width-964px text-center mt-4 text-white">
          Slara.AI offers flexible subscription plans tailored to your
          discussion needs. Whether you're a casual user, a regular learner, or
          a power user, we have the right plan for you.
        </p>

        {/* <div className="tabMainParent text-white">
          <div
            style={{ width: "50%" }}
            className="py-2 bg-Set-Card d-flex rounded-pill justify-content-between m-auto mtSet border p-1 px-2"
          >
            <button
              className={`rounded-pill shadow w-50 ${
                selectedPlan === "individual" ? "bg-9b28a8" : "bg-transparent"
              } px-3 py-2 `}
              onClick={() => setSelectedPlan("individual")}
            >
              Individual Plan
            </button>
            <button
              className={`rounded-pill shadow w-50 ${
                selectedPlan === "enterprise" ? "bg-9b28a8" : "bg-transparent"
              } px-3 py-2`}
              onClick={() => setSelectedPlan("enterprise")}
            >
              Enterprise Plan
            </button>
            <button
              className={`rounded-pill shadow w-50 ${
                selectedPlan === "textBased" ? "bg-9b28a8" : "bg-transparent"
              } px-3 py-2`}
              onClick={() => setSelectedPlan("textBased")}
            >
              Text-Based Interactions
            </button>
          </div>
        </div> */}

        <div className="text-white container d-flex mt-5 justify-content-center SetForSmall">
          {selectedPlan === "individual" &&
            individualPlans
              .filter((plan) =>
                userHasPlan?.plan_name ? plan.id !== "Free_Trial" : true
              )
              .map((plan) => (
                <div
                  key={plan.id}
                  data-plan-name={plan.id}
                  data-plan-price={plan.price}
                  style={{
                    minHeight: "400px",
                    height: "100%",
                  }}
                  className="col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
                >
                  <div className="container m-auto">
                    <h3 className="text-center mt-2">{plan.title}</h3>
                    <p className="text-center mt-3">${plan.price} / Monthly</p>
                    {/* <p className="text-center mt-3">/Monthly</p> */}
                    <button
                      className="dash-pink-btn w-100 mt-3 mb-1"
                      onClick={() => handleGetStarted(plan.id, plan.price)}
                    >
                      {userHasPlan?.plan_name ? "Upgrade Now" : plan.buttonText}
                    </button>
                  </div>
                  {plan.features.map((feature, index) => (
                    <div
                      key={index}
                      className="container d-flex align-items-center mt-3 w-100"
                    >
                      <div className="w-25 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31"
                          height="31"
                          viewBox="0 0 31 31"
                          fill="none"
                        >
                          <circle
                            cx="15.3289"
                            cy="15.1858"
                            r="15.1858"
                            fill="#9208F3"
                            fillOpacity="0.2"
                          />
                          <path
                            d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                            stroke="#9B28A8"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <div className="w-75 mb-0">
                        <p className="mb-0">{feature}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

          {selectedPlan === "enterprise" &&
            enterprisePlans.map((plan) => (
              <div
                key={plan.id}
                data-plan-name={plan.id}
                data-plan-price={plan.price}
                className="col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
              >
                <div className="container m-auto">
                  <h3 className="text-center">{plan.title}</h3>
                  <p className="text-center mt-3">${plan.price}</p>
                  <p className="text-center mt-3">/Monthly</p>
                  <button
                    className="dash-pink-btn w-100 mt-3"
                    onClick={() => handleGetStarted(plan.id, plan.price)}
                  >
                    {userHasPlan?.plan_name ? "Upgrade Now" : plan.buttonText}
                  </button>
                </div>
                {plan.features.map((feature, index) => (
                  <div
                    key={index}
                    className="container d-flex align-items-center mt-3 w-100"
                  >
                    <div className="w-25 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                      >
                        <circle
                          cx="15.3289"
                          cy="15.1858"
                          r="15.1858"
                          fill="#9208F3"
                          fillOpacity="0.2"
                        />
                        <path
                          d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                          stroke="#9B28A8"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className="w-75 mb-0">
                      <p className="mb-0">{feature}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}

          {selectedPlan === "textBased" &&
            textBasedPlans.map((plan) => (
              <div
                key={plan.id}
                data-plan-name={plan.id}
                data-plan-price={plan.price}
                className="col-lg-4 col-md-12 col-sm-12 col-12 bg-Set-Card rounded-4 py-3 setHeight border m-10 "
              >
                <div className="container m-auto">
                  <h3 className="text-center">{plan.title}</h3>
                  <p className="text-center mt-3">${plan.price}</p>
                  <p className="text-center mt-3">/Monthly</p>
                  <button
                    className="dash-pink-btn w-100 mt-3"
                    onClick={() => handleGetStarted(plan.id, plan.price)}
                  >
                    {userHasPlan?.plan_name ? "Upgrade Now" : plan.buttonText}
                  </button>
                </div>
                {plan.features.map((feature, index) => (
                  <div
                    key={index}
                    className="container d-flex align-items-center mt-3 w-100"
                  >
                    <div className="w-25 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                      >
                        <circle
                          cx="15.3289"
                          cy="15.1858"
                          r="15.1858"
                          fill="#9208F3"
                          fillOpacity="0.2"
                        />
                        <path
                          d="M8.31982 15.6017L12.2378 19.5196C12.6464 19.9282 13.3152 19.9065 13.6964 19.4722L22.1431 9.84937"
                          stroke="#9B28A8"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className="w-75 mb-0">
                      <p className="mb-0">{feature}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
