import React, { useContext, useEffect } from "react";
import PrivacyPopUpModal from "./PrivacyPopUpModal";
import TermsPopUpModal from "./TermsPopUpModal";
import { Modal } from 'bootstrap';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";

function SignUpModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoginFromPath } = useContext(AppContext);

  useEffect(() => {
    const mainModalElement = document.getElementById('addSIgnUpPopup');
    const firstModalElement = document.getElementById('termsPopUp');
    const secondModalElement = document.getElementById('privacyPopUp');

    const mainModal = new Modal(mainModalElement);
    const firstModal = new Modal(firstModalElement);
    const secondModal = new Modal(secondModalElement);

    const termsButton = document.getElementById('terms');
    const privacyButton = document.getElementById('privacy');
    const backToFirstModalButtons = document.querySelectorAll('#backToFirstModal, #backToFirstModal2');

    const handleBackToMainModal = () => {
      firstModal.hide();
      secondModal.hide();
      // Clear any existing modal backdrops
      document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
      mainModal.show();
    };

    termsButton.addEventListener('click', () => {
      mainModal.hide();
      // Clear any existing modal backdrops
      document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
      firstModal.show();
    });

    privacyButton.addEventListener('click', () => {
      mainModal.hide();
      // Clear any existing modal backdrops
      document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
      secondModal.show();
    });

    backToFirstModalButtons.forEach(button => {
      button.addEventListener('click', handleBackToMainModal);
    });
  }, []);

  // const navigateLogin =(path)=>{
  //   // Clear any existing modal backdrops
  //   document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
  //   navigate(`/${path}`)
  // }
  
  const navigateLogin = (path) =>{
    document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());

    if(location.pathname === '/meeting'){
      setLoginFromPath("meeting");
    }else{
      setLoginFromPath("home");
    }
    navigate(path);
  }

  return (
    <>
      {/* SignUp Modal  */}
      <div
        className="modal fade"
        id="addSIgnUpPopup"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-modal signUp-model">
            <i
              className="fa-sharp fa-solid fa-circle-xmark"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <h3>Sign In</h3>
            <p>To continue meeting first</p>
            <div className="sign-in-btn my-2">
              <button className="first-button" onClick={()=>navigateLogin('/signin')}>Sign In</button>
              <p className="mt-3">Or</p>
              <button className="second-button" onClick={()=>navigateLogin('/signup')}>Create Account</button>
            </div>
            <div className="terms">
              <p>
                By continuing, you agree to our <u id="terms">Terms</u>
              </p>
              <p>
                {" "}
                and acknowledge our <u id="privacy"> Privacy Policy</u>
              </p>
            </div>
          </div>
        </div>
      </div>
      <PrivacyPopUpModal />
      <TermsPopUpModal />
    </>
  );
}

export default SignUpModal;
