import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../Context/AppContext";

import Modal from 'react-bootstrap/Modal';

function CustomPersonaModal({
  userId, 
  topicDetails,  
  customAddPopUpShow, 
  handleCustomClose
}) {
  const { userHasPlan, setPersonaItems, personas } = useContext(AppContext);
  const [wantsDiverse, setWantsDiverse] = useState(false);
  const [active, setActive] = useState(0);
  const [activBtn, setActiveBtn]=useState(true);
  const navigate = useNavigate();
  const usertype = secureLocalStorage.getItem("usertype");
  const [isLoading, setIsLoading]=useState(false);

  const handlechangepersonas = (value) => {
    if (!isValidPersonaCount(value)) {
      toast.error("Upgrade your plan to select more personas!");
      setActiveBtn(false);
      return false;
    }else{
      setActiveBtn(true);
      return true;
    }
    return true;
  };

  const isValidPersonaCount = (count) => {
    const ExCount = personas?.length;
    switch (userHasPlan?.plan_name) {
      case "Text_Base_Intraction_Only":
        return count <= 3-ExCount;
      case "Free_Trial":
        return count <= 2-ExCount;
      case "Starter_Individual":
        return count <= 5-ExCount;
      case "Starter_Enterprise":
      case "Standard_Individual":
      case "Plus_Enterprise":
        return count <= 10-ExCount;
      default:
        return true;
    }
  };

  const handleSelectChange = async () => {
    if (active === 0) {
      toast.error("Please Enter the number of personas before continuing.");
      return;
    }
  
    const payload = {
      userid: userId,
      topic: topicDetails,
      number_of_persona: active,
      diversity: wantsDiverse,
    };
  
    if (activBtn && handlechangepersonas(active)) {
      try {
        setIsLoading(true);
        setActiveBtn(false)
        // Initialize seenNames set with existing unique personas
        const seenNames = new Set(personas.map(persona => persona.name));
        const uniquePersonas = [];
  
        while (uniquePersonas.length < active) {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
            payload
          );
          const personasData = response.data.personas;
  
          // Filter out personas with unique names
          personasData.forEach(persona => {
            if (!seenNames.has(persona.name) && uniquePersonas.length < active) {
              seenNames.add(persona.name);
              uniquePersonas.push(persona);
            }
          });
        }
  
        // Ensure the uniquePersonas array does not exceed the desired length
        if (uniquePersonas.length > active) {
          uniquePersonas.length = active;
        }
  
        console.log("Unique personas:", uniquePersonas);
        setPersonaItems(uniquePersonas);

        // Use Promise.all to handle multiple requests concurrently 
        const personasPromises = uniquePersonas.map(persona => { 
          return axios.post( `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`, 
            { 
              userid: userId, 
              persona_name: persona?.name, 
              persona_description: persona?.description, 
              image: "../assetsNew/image/user.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
            } 
          ); 
        }); 
        
        const personasResponses = await Promise.all(personasPromises); 
  
      } catch (error) {
        console.error("Error fetching personas:", error);
        // toast.error("There is an issue generating personas, Please try again.");
      } finally {
        //close custom Modal
        handleCustomClose();
        setIsLoading(false);
        setActiveBtn(true);
      }
    }
  };

  return (
    // -- Custom Persona Modal --
    <Modal 
      show={customAddPopUpShow} 
      onHide={handleCustomClose}
      centered
      contentClassName="modal-content custom-modal persona-model"
    >
          <i
            className="fa-sharp fa-solid fa-circle-xmark"
            // data-bs-dismiss="modal"
            // aria-label="Close"
            onClick={handleCustomClose}
          />
          <h4>How Many Persona’s You Want to Add for Discussion</h4>
          <div className="d-flex flex-column gap-4 justify-content-center align-items-center custom-add-persona">
            <input
              value={active}
              onChange={(e)=>setActive(e.target.value)}
              type="number"
              placeholder="Add Number of Persona’s for Discussion"
            />
            <button 
              id="endAllPopup" 
              disabled={!activBtn}
              onClick={handleSelectChange}
            >{isLoading ? "Loading ..." : "Continue" }</button>
          </div>
    </Modal>
  );
}

export default CustomPersonaModal;
