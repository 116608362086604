import React, { useContext, useEffect } from "react";
import Logo from '../assetsNew/image/logo.png';
import VectorImage from '../assetsNew/image/header-vector.svg';
import Arrow from '../assetsNew/image/Arrow.svg';
import { useLocation, useNavigate } from "react-router-dom";
import SignUpModal from "../modalsNew/SignUpModal";
import { AppContext } from "../Context/AppContext";
import ProfilePic from "../assetsNew/image/meeting/animoji.png";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

function Navbar({NavButtons}) {
    const profileData = secureLocalStorage.getItem("profileData");
    const { logout, ProfileData, setUserHasPlan } = useContext(AppContext);
    const userType = secureLocalStorage.getItem("usertype");
    const userId = secureLocalStorage.getItem("userId");
    // console.log("Profile1: ",profileData, userType);
    // console.log("userId: ",userId);

    const baseUrl = window.location.origin;

    const navigate = useNavigate();
    const location = useLocation();

    const isHomePage = location.pathname === "/";

    const handleLogout = ()=>{
        // localStorage.setItem("personas", JSON.stringify([]));
        logout();
        navigate('/');
    }

    const handleGetStarted = async (name, price) => {
        try {
          const payload = profileData?.email
            ? {
                userid: userId,
                amount: price,
                plan_name: name,
                email: profileData?.email,
                base_url: baseUrl || "https://www.slara.ai",
              }
            : {
                userid: "",
                amount: 0.0,
                plan_name: name,
                email: "abc@gmail.com",
                base_url: "",
              };
    
          // Send request to activate the free trial
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/create-checkout-session`,
            payload
          );
    
          // Handle the response based on different cases
          if (response.data.save === "save plan") {
            setUserHasPlan("Free_Trial");
            toast.success("Free trial activated!");
            await ProfileData();
            // navigate("/");
          } else if (response.data["Already subscribed"]) {
            // toast.error(
            //   response.data["Already subscribed"] ||
            //     "You have already used your free trial!"
            // );
          } else if (response.data.Response) {
            const { userid, token, plan, guest_id } = response.data.Response;
    
            // Save necessary data in secure storage
            secureLocalStorage.setItem("authToken", token);
            secureLocalStorage.setItem("userId", userid);
            secureLocalStorage.setItem("guestId", guest_id);
            secureLocalStorage.setItem("usertype", "guest");
    
            setUserHasPlan(plan);
            // toast.success("Free Trial Activated!");
            await ProfileData();
            // navigate("/");
          } else {
            // toast.error("You already have a plan");
          }
        } catch (error) {
          console.error("Error activating free trial:", error);
        //   toast.error("Something went wrong. Please try again later.");
        }
    };

    const openSetting = ()=>{
        console.log("profileData in navbar : ", profileData);
        if(profileData?.email !== 'abc@gmail.com'){
            navigate('/settings');
        }
    }

    useEffect(()=>{
        if(profileData && profileData?.email !== 'abc@gmail.com'){
            // console.log("userprofileData in navbar : ", profileData);
            handleGetStarted("Free_Trial", "00.00");
        }
    },[userId, profileData])
    
    return (
        <>
            {/* header */}
            <header className="container-fluid ">
                <div className="container d-flex justify-content-between p-3">
                    <div className="logo z-3" onClick={() => navigate('/')}>
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="header-vector" id="vector-svg">
                        <img src={VectorImage} alt="vector image" />
                    </div>

                    <div className="d-flex header-btn">

                        {profileData && (
                            <div className="profile-div mx-3" onClick={openSetting}>
                                <img src={profileData?.picture || ProfilePic} alt="profilePic" referrerPolicy="no-referrer" />
                                <p className="text-light ms-1">
                                    {profileData?.username}
                                </p>
                            </div>
                        )}

                        {NavButtons && NavButtons?.map((btn, index)=>(
                            <button
                                key={index}
                                className="me-3 linear-btn"
                                onClick={() => navigate(btn?.path)}
                            >
                                <span>{btn?.title}</span>
                            </button> 
                        ))}

                        {/* {isHomePage && !profileData && (
                            <button
                                className="me-3 linear-btn"
                                onClick={() => handleGetStarted("Free_Trial", "00.00")}
                            >
                                <span>Start Free Trial</span>
                            </button> 
                        )} */}


                        {profileData ?  
                            <button
                                className=" linear-btn"
                            >
                                <span onClick={handleLogout}>
                                    Logout
                                    <i class="fa fa-sign-out ms-2" aria-hidden="true"></i>
                                </span>
                            </button>
                            :
                            <button
                                className=" linear-btn"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#addSIgnUpPopup"
                            >
                                <span>
                                    SIGN IN
                                    <img src={Arrow} alt="arrow" />
                                </span>
                            </button>
                        }
                    </div>
                </div>
            </header>
            <SignUpModal />
        </>
    );
}

export default Navbar;
