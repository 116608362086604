import React from "react";

function TermsPopUpModal() {
  return (
    <>
      {/* termsPopUp Modal */}
      <div
        className="modal fade"
        id="termsPopUp"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered privacy-model-width">
          <div className="modal-content privacy-model p-3">
            <i
              className="fa-sharp fa-solid fa-circle-xmark text-end"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <h3>Terms and Conditions</h3>
            <p>
              By accessing or using Slara.AI, you agree to the following terms:
            </p>
            <ol className="my-4">
              <li>
                <span>Service Usage:</span> You can use Slara.AI for
                discussions, document uploads, and other features within the
                limits of your subscription tier.
              </li>
              <li>
                <span>Account Responsibility:</span> You are responsible for
                maintaining the confidentiality of your account details. Notify
                us immediately of any unauthorized access.
              </li>
              <li>
                <span>Content Ownership:</span> You retain ownership of
                documents you upload, but grant us a license to process and
                analyze them.
              </li>
              <li>
                <span>Prohibited Use:</span> You agree not to use the platform
                for illegal activities, spamming, or infringing on intellectual
                property.
              </li>
              <li>
                <span>Subscription Fees:</span> Subscription fees are charged
                monthly. Refunds are not provided for partial months of service.
              </li>
              <li>
                <span>Liability:</span> We are not liable for any indirect,
                incidental, or consequential damages resulting from your use of
                the service.
              </li>
              <li>
                <span>Changes:</span> We may update these terms at any time.
                Continued use of Slara.AI after changes signifies your
                acceptance of the new terms.
              </li>
            </ol>
            <p>By using Slara.AI, you agree to these Terms and Conditions.</p>
            <p id="backToFirstModal">
              <i className="fa-solid fa-backward me-2" />
              Get Back
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsPopUpModal;
