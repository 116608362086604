import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function ShareLink() {
  const { topic } = useParams();
  const navigate = useNavigate();

  const ActualTopic = decodeURIComponent(topic);

  useEffect(() => {
    if (topic) {
      localStorage.setItem("topic", ActualTopic);
      navigate('/meeting');
    } else {
      navigate('/');
    }
  }, [topic, navigate]); 

  return null; 
}

export default ShareLink;
