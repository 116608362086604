import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { AppContext } from "../Context/AppContext";
import NewTermsPopUp from "./NewTermsPopPup";
import NewPrivacyPopUp from "./NewPrivacyPopUp";

function OnlySignInUpModal({
  signInUpPopUp,
  handleSignInUpPopUpClose,
  handleSignInUpPopUpShow
}) {
  const navigate = useNavigate();
  const { setLoginFromPath } = useContext(AppContext);

  const [termsPopUp, setTermsPopUp] = useState(false);
  const handleTermsPopUpClose = useCallback(() => setTermsPopUp(false), []);
  const handleTermsPopUpShow = useCallback(() => setTermsPopUp(true), []);

  const [privacyPopUp, setPrivacyPopUp] = useState(false);
  const handlePrivacyPopUpClose = useCallback(() => setPrivacyPopUp(false), []);
  const handlePrivacyPopUpShow = useCallback(() => setPrivacyPopUp(true), []);

  const handleLogin = (path) =>{
    setLoginFromPath("meeting");
    navigate(path);
  }

  const handleOpenPrivacy = () =>{
    handleSignInUpPopUpClose();
    handlePrivacyPopUpShow();
  }

  const handleOpenTerms = () =>{
    handleSignInUpPopUpClose();
    handleTermsPopUpShow();
  }
 
  return (
    <>
      {/* SignUp Modal  */}
      <Modal 
        show={signInUpPopUp} 
        onHide={handleSignInUpPopUpClose}
        centered
        contentClassName="modal-content custom-modal signUp-model"
      >
        <i
            className="fa-sharp fa-solid fa-circle-xmark"
            onClick={handleSignInUpPopUpClose}
        />
        <h3>Sign In</h3>
        <p>To continue meeting first</p>
        <div className="sign-in-btn my-2">
            <button className="first-button" onClick={()=>handleLogin('/signin')}>Sign In</button>
            <p className="mt-3">Or</p>
            <button className="second-button" onClick={()=>handleLogin('/signup')}>Create Account</button>
        </div>
        <div className="terms">
            <p>
            By continuing, you agree to our <u id="terms" onClick={handleOpenTerms}>Terms</u>
            </p>
            <p>
            {" "}
            and acknowledge our <u id="privacy" onClick={handleOpenPrivacy}> Privacy Policy</u>
            </p>
        </div>
      </Modal>

      <NewTermsPopUp 
        termsPopUp={termsPopUp} 
        handleTermsPopUpClose={handleTermsPopUpClose}
        handleSignInUpPopUpShow={handleSignInUpPopUpShow}
      />
      <NewPrivacyPopUp
        privacyPopUp={privacyPopUp}
        handlePrivacyPopUpClose={handlePrivacyPopUpClose}
        handleSignInUpPopUpShow={handleSignInUpPopUpShow}
      />
    </>
  );
}

export default OnlySignInUpModal;
