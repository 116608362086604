import React, { useContext, useEffect, useState } from 'react';
import CustomPersonaModal from "./CustomPersonaModal";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from '../Context/AppContext';

import Modal from 'react-bootstrap/Modal';

const AddPersonaPopup = ({
  addPopUpShow,
  handleAddPopUpClose,

  customAddPopUpShow,
  handleCustomShow,
  handleCustomClose
}) => {

  const topicDetails = localStorage.getItem("topic");
  const navigate = useNavigate();
  const [iscustomModalOpen, setiscustomModalOpen] = useState(false);
  const { personas, setPersonaItems, userHasPlan } = useContext(AppContext);
  const usertype = secureLocalStorage.getItem("usertype");
  // console.log("personas",personas);
  // console.log("topicDetails",topicDetails);
  const [active, setActive] = useState(null);
  const [wantsDiverse, setWantsDiverse] = useState(false);
  const userId = secureLocalStorage.getItem("userId");
  const [activBtn, setActiveBtn]=useState(true);
  const [isLoading, setIsLoading]=useState(false);


  const handlechangepersonas = (value) => {
    if (!isValidPersonaCount(value)) {
      toast.error("Upgrade your plan to select more personas!");
      setActiveBtn(false);
      return;
    }else{
      setActiveBtn(true);
    }
    const generateUniqueId = () => {
      return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    };

    const newPersonas = Array.from({ length: value }, () => ({
      id: generateUniqueId(),
    }));

    // setPersonaItems(newPersonas);
    setActive(value);
  };

  const isValidPersonaCount = (count) => {
    const ExCount = personas?.length;
    switch (userHasPlan?.plan_name) {
      case "Text_Base_Intraction_Only":
        return count <= 3-ExCount;
      case "Free_Trial":
        return count <= 2-ExCount;
      case "Starter_Individual":
        return count <= 5-ExCount;
      case "Starter_Enterprise":
      case "Standard_Individual":
      case "Plus_Enterprise":
        return count <= 10-ExCount;
      default:
        return true;
    }
  };

  const handleSelectChange = async () => {
    if (active === null) {
      toast.error("Please select the number of personas before continuing.");
      return;
    }
  
    const payload = {
      userid: userId,
      topic: topicDetails,
      number_of_persona: active,
      diversity: wantsDiverse,
    };
  
    if (activBtn) {
      try {
        setIsLoading(true);
        setActiveBtn(false);
  
        // Initialize seenNames set with existing unique personas
        const seenNames = new Set(personas.map(persona => persona.name));
        const uniquePersonas = [];
  
        while (uniquePersonas.length < active) {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/persona/continue_after_topic_selecting`,
            payload
          );
          const personasData = response.data.personas;
  
          // Filter out personas with unique names
          personasData.forEach(persona => {
            if (!seenNames.has(persona.name) && uniquePersonas.length < active) {
              seenNames.add(persona.name);
              uniquePersonas.push(persona);
            }
          });
        }
  
        // Ensure the uniquePersonas array does not exceed the desired length
        if (uniquePersonas.length > active) {
          uniquePersonas.length = active;
        }
  
        console.log("Unique personas:", uniquePersonas);
        setPersonaItems(uniquePersonas);

        //close Main Popup
        handleAddPopUpClose();

        // Use Promise.all to handle multiple requests concurrently 
        const personasPromises = uniquePersonas.map(persona => { 
          return axios.post( `${process.env.REACT_APP_BASE_URL}/persona/generate_persona`, 
            { 
              userid: userId, 
              persona_name: persona?.name, 
              persona_description: persona?.description, 
              image: "../assetsNew/image/user.png", 
              voice_settings: { 
                stability: "0.71", 
                similarity_boost: "0.5", 
                style: "0", 
                speaker_boost: "false", 
              }, 
              usertype: usertype, 
              isFavourite: "0"
            } 
          ); 
        }); 
        
        const personasResponses = await Promise.all(personasPromises); 
  
      } catch (error) {
        console.error("Error fetching personas:", error);
        // toast.error("There is an issue generating personas, Please try again.");
      } finally {
        setIsLoading(false);
        setActiveBtn(true);
      }
    }
  };
  
  const handelOpenCustom = ()=>{
    handleAddPopUpClose();
    handleCustomShow();
  }
  
  

  return (
    <>
      <Modal 
        show={addPopUpShow} 
        onHide={handleAddPopUpClose}
        centered
        contentClassName="modal-content custom-modal persona-model"
      >
            <i
              className="fa-sharp fa-solid fa-circle-xmark"
              // data-bs-dismiss="modal"
              // aria-label="Close"
              onClick={handleAddPopUpClose}
            />
            <h4>How Many Persona’s You Want to Add for Discussion?</h4>
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#custom-person-popup"
                // id="custom-persona-models"
                onClick={handelOpenCustom}
              >
                Custom
              </button>
              <button onClick={() => handlechangepersonas(1)}>1</button>
              <button onClick={() => handlechangepersonas(2)}>2</button>
              <button onClick={() => handlechangepersonas(4)}>4</button>
              <button onClick={() => handlechangepersonas(6)}>6</button>
              <button className="signInn text-center" id="continue-btn" disabled={!activBtn} onClick={handleSelectChange}>{isLoading ? "Loading ..." : "Continue" }</button>
            </div>
      </Modal>

      <CustomPersonaModal
        userId={userId} 
        topicDetails={topicDetails}

        customAddPopUpShow={customAddPopUpShow}
        handleCustomClose={handleCustomClose}
      />
    </>
  );
};

export default React.memo(AddPersonaPopup);
