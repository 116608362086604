import React, { useContext, useEffect, useState } from "react";
import userImage from "../assetsNew/image/user.png";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import Accordion from 'react-bootstrap/Accordion';
import { AppContext } from "../Context/AppContext";
import Modal from 'react-bootstrap/Modal';

const EditPersonaPopup = ({personaOne, handleEditPopUpClose, editPopUp}) => {
  // console.log("selected person", personaOne);

  const { personas, setPersonas } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [clearForm, setClearForm] = useState(false);
  const userId = secureLocalStorage.getItem("userId");

  const [onePersonaDetails, setOnePersonaDetails] = useState({
    name: personaOne?.name,
    description: personaOne?.description,
    image: personaOne?.image ?  personaOne?.image : "./user.png",
    isFavourite: personaOne?.isFavourite ? personaOne?.isFavourite : 0,
    voice_setting: {
      siimilarity_boost: personaOne?.voice_setting?.siimilarity_boost ? personaOne?.voice_setting?.siimilarity_boost : 0.5,
      speaker_boost: personaOne?.voice_setting?.speaker_boost ? personaOne?.voice_setting?.speaker_boost : false,
      stability: personaOne?.voice_setting?.stability ? personaOne?.voice_setting?.stability : 0.7,
      style: personaOne?.voice_setting?.style ? personaOne?.voice_setting?.style : 0,
    }
  })

  const removeFormData = ()=>{
    setClearForm(!clearForm);
    handleEditPopUpClose();
  }

  // updatePersonaData
  const updatePersonaData = (personaOne)=>{
    setOnePersonaDetails({
      ...onePersonaDetails,
      image: personaOne?.image ?  personaOne?.image : "./user.png",
      isFavourite: personaOne?.isFavourite ? personaOne?.isFavourite : 0,
      voice_setting: {
        siimilarity_boost: personaOne?.voice_setting?.siimilarity_boost ? personaOne?.voice_setting?.siimilarity_boost : 0.5,
        speaker_boost: personaOne?.voice_setting?.speaker_boost ? personaOne?.voice_setting?.speaker_boost : false,
        stability: personaOne?.voice_setting?.stability ? personaOne?.voice_setting?.stability : 0.7,
        style: personaOne?.voice_setting?.style ? personaOne?.voice_setting?.style : 0,
      }
    })
  }

  useEffect(()=>{
    const updatePersonaData = ()=>{
      setOnePersonaDetails({
        name: personaOne?.name,
        description: personaOne?.description,
        image: personaOne?.image ?  personaOne?.image : "./user.png",
        isFavourite: personaOne?.isFavourite ? personaOne?.isFavourite : 0,
        voice_setting: {
          siimilarity_boost: personaOne?.voice_setting?.siimilarity_boost ? personaOne?.voice_setting?.siimilarity_boost : 0.5,
          speaker_boost: personaOne?.voice_setting?.speaker_boost ? personaOne?.voice_setting?.speaker_boost : false,
          stability: personaOne?.voice_setting?.stability ? personaOne?.voice_setting?.stability : 0.7,
          style: personaOne?.voice_setting?.style ? personaOne?.voice_setting?.style : 0,
        }
      })
    }

    updatePersonaData();
  },[personaOne?.name, clearForm])

  const handleSetPerson = (e)=>{
    const {name, value} = e.target;
    setOnePersonaDetails({
      ...onePersonaDetails,
      [name] : value
    })
  }

  const handleSetVoice_Setting = (e) => {
    const { name, value } = e.target;
    setOnePersonaDetails({
      ...onePersonaDetails,
      voice_setting: {
        ...onePersonaDetails.voice_setting,
        [name]: value,
      },
    });
  };
  
  const handleSpeaker = (value) =>{
    setOnePersonaDetails({
      ...onePersonaDetails,
      voice_setting: {
        ...onePersonaDetails.voice_setting,
        speaker_boost: value,
      },
    });
  }

  const triggerFileInput = () => {
    document.getElementById(`imageUploadInput`).click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setOnePersonaDetails({
          ...onePersonaDetails,
          image : base64Image
        })
        localStorage.setItem(`userImage`, base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const sliders = document.querySelectorAll(".range-slider");

    const updateValuePosition = (slider) => {
      const span = slider.nextElementSibling;
      const sliderWidth = slider.offsetWidth;
      const thumbOffset =
        ((slider.value - slider.min) / (slider.max - slider.min)) * sliderWidth;
      span.textContent = slider.value;
      span.style.left = `${thumbOffset}px`;
      span.style.transform = `translateX(-50%) translateY(-50%)`;
    };

    sliders.forEach((slider) => {
      const updateHandler = () => updateValuePosition(slider);
      slider.addEventListener("input", updateHandler);
      updateValuePosition(slider);

      // Cleanup on component unmount
      return () => {
        slider.removeEventListener("input", updateHandler);
      };
    });
  }, []);

  const checkValidName = (name)=>{
    return personas.some(
      (persona) => persona.name === name && persona.name !== personaOne.name
    );
  }

  const handleContinue = async () => {
    
    if(onePersonaDetails?.name === ''){
      toast.error("The persona name should not be empty.");
      return;
    }else if(checkValidName(onePersonaDetails?.name)){
      toast.error("The persona name already exists. Please change it.");
      return;
    }
    
    setIsLoading(true);

    try {
      // First API call
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/persona/update_persona/${userId}/${personaOne?.name}`,
        {
          new_persona_name: onePersonaDetails?.name || personaOne?.name,
          new_image: onePersonaDetails?.image || "./user.png",
          new_description: onePersonaDetails?.description || personaOne?.description,
          new_voice_setting: {
            stability: onePersonaDetails?.voice_setting?.stability,
            siimilarity_boost: onePersonaDetails?.voice_setting?.siimilarity_boost,
            style: onePersonaDetails?.voice_setting?.style,
            speaker_boost: onePersonaDetails?.voice_setting?.speaker_boost,
          },
        }
      );
  
      // Update personas locally
      const updatedPersonas = personas.map((person) =>
        person.name === personaOne.name ? onePersonaDetails : person
      );
  
      console.log("Edit update Personas:", updatedPersonas);
  
      setPersonas(updatedPersonas);
      localStorage.setItem("personas", JSON.stringify(updatedPersonas));
      toast.success("Persona updated successfully");
  
      // Second API call
      const personasName = updatedPersonas.map((persona) => persona.name);
  
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/persona/continue_after_saving_persona`,
        {
          userid: userId,
          personas: personasName,
        }
      );
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating persona:", error);
      // toast.error(error?.response?.data?.detail || "Issue Updating Persona");
    } finally {
      setIsLoading(false);
      // Close Popup
      handleEditPopUpClose();
    }
  };  


  return (
      <Modal 
        show={editPopUp} 
        onHide={handleEditPopUpClose}
        centered
        contentClassName="modal-content custom-modal persona-model edit-parsona"
      >
          <i
            className="fa-sharp fa-solid fa-circle-xmark"
            // data-bs-dismiss="modal"
            // aria-label="Close"
            onClick={handleEditPopUpClose}
          ></i>
          <h4>Update your persona for discussion</h4>
          <div className="edit-persona-body">
            <div className="d-flex align-items-center justify-content-center gap-3 user-btn">
              <img src={onePersonaDetails?.image ? onePersonaDetails?.image : userImage} alt="user" />
              <button onClick={triggerFileInput}>Upload Image</button>
              <input
                type="file"
                id="imageUploadInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </div>
            <input 
              type="text" 
              name="name"
              value={onePersonaDetails?.name}
              onChange={handleSetPerson}
              placeholder="Enter Name of the Persona" />
            <p>Description Box</p>
            <textarea  
              name='description'
              value={onePersonaDetails?.description}
              onChange={handleSetPerson}
              placeholder="35 years old Teacher, married with 2 kids, education: MBA, enjoys traveling and hiking">
            </textarea>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Choose Existing Persona</Accordion.Header>
                <Accordion.Body>
                  <div className="accordion-body">
                    {personas.map((person, i) => (
                      <button 
                        key={i}
                        onClick={()=>updatePersonaData(person)}
                      >{person?.name}</button>
                    ))}
                    
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <p>Advanced Settings</p>
   
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header> Advanced Settings </Accordion.Header>
                <Accordion.Body>
                <div className="accordion-body">
                  <div className="Range-item">
                    <p>Stability</p>
                    <div className="range-container">
                      <label>0</label>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        // defaultValue="0.5"
                        className="range-slider"
                        name="stability"
                        value={onePersonaDetails?.voice_setting?.stability}
                        onChange={handleSetVoice_Setting}
                      />
                      <span className="range-value">{onePersonaDetails?.voice_setting?.stability}</span>
                      <label>1</label>
                    </div>
                  </div>
                  <div className="Range-item">
                    <p>Similarity Boost</p>
                    <div className="range-container">
                      <label>0</label>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        // defaultValue="0.5"
                        className="range-slider"
                        name="siimilarity_boost"
                        value={onePersonaDetails?.voice_setting?.siimilarity_boost}
                        onChange={handleSetVoice_Setting}
                      />
                      <span className="range-value">{onePersonaDetails?.voice_setting?.siimilarity_boost}</span>
                      <label>1</label>
                    </div>
                  </div>
                  <div className="Range-item">
                    <p>Style</p>
                    <div className="range-container">
                      <label>0</label>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        // defaultValue="0.5"
                        className="range-slider"
                        name="style"
                        value={onePersonaDetails?.voice_setting?.style}
                        onChange={handleSetVoice_Setting}
                      />
                      <span className="range-value">{onePersonaDetails?.voice_setting?.style}</span>
                      <label>1</label>
                    </div>
                  </div>
                </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


            <Accordion className="mt-2">
              <Accordion.Item eventKey="3">
                <Accordion.Header>Speaker Boost</Accordion.Header>
                <Accordion.Body>
                  <div className="accordion-body">
                    <button 
                      onClick={()=>handleSpeaker(true)} 
                      style={{backgroundColor: onePersonaDetails?.voice_setting?.style ? "#464a4d" : ""}}
                    >True</button>
                    <button 
                      onClick={()=>handleSpeaker(false)} 
                      style={{backgroundColor: onePersonaDetails?.voice_setting?.style ? "" : "#464a4d"}}
                    >False</button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="d-flex align-items-center justify-content-start edit-persona-btn gap-2">
              <button 
                className="first-button" 
                onClick={removeFormData}
              >Clear</button>
              <button className="second-button" disabled={isLoading} onClick={handleContinue}>{isLoading ? "Loading ..." : "Save"}</button>
            </div>
          </div>
        </Modal>
  );
};

export default React.memo(EditPersonaPopup);
