import React from "react";

function PrivacyPopUpModal() {
  return (
    <>
      {/* privacyPopUp Modal */}
      <div
        className="modal fade"
        id="privacyPopUp"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered privacy-model-width">
          <div className="modal-content privacy-model p-3">
            <i
              className="fa-sharp fa-solid fa-circle-xmark text-end"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <h3>Privacy Policy</h3>
            <p>
              At Slara.AI, we prioritize your privacy. This policy explains how
              we collect, use, and protect your data:
            </p>
            <ol className="my-4">
              <li>
                <span>Data Collection:</span> We collect information such as
                your name, email, and documents you upload for analysis. We may
                also collect usage data through cookies.
              </li>
              <li>
                <span>Use of Data:</span> Your data is used to provide services,
                improve our platform, and communicate with you. Uploaded
                documents are processed for discussion purposes and stored for
                future use if desired.
              </li>
              <li>
                <span>Data Protection:</span> We use industry-standard security
                measures to protect your data. However, we cannot guarantee
                absolute security.
              </li>
              <li>
                <span>Third Parties:</span> We do not share your personal
                information with third parties without your consent, except as
                required by law.
              </li>
              <li>
                <span>Your Rights:</span> You can access, modify, or delete your
                personal data by contacting us.
              </li>
            </ol>
            <p>By using Slara.AI, you consent to this privacy policy.</p>
            <p id="backToFirstModal2">
              <i className="fa-solid fa-backward me-2" />
              Get Back
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPopUpModal;
