import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
export const AppContext = createContext();

const individualPlans = [
  {
    id: "Free_Trial",
    title: "Free Trial",
    price: "00.00",
    buttonText: "Get Started",
    features: [
      "Agents available: 2 agents for discussions.",
      "Discussions: 1 free trial discussion.",
      "Duration: 30-minute limit for the trial session.",
      "Document Upload: Available for the trial. These features will not be available.",
      "They only can choose a topic and start a discussion for about 15 min.",
      // "Post-Discussion Summary: Provided after the trial session.",
    ],
  },
  {
    id: "Starter_Individual",
    title: "Starter Tier",
    price: "20.00",
    buttonText: "Get Started",
    features: [
      "Access to 5 agents for discussions.",
      "7 discussions Document upload and training available.",
      // "Post-discussion summaries for all sessions.",
      "30-minute discussion limit per session.",
      // "Their meeting will be recorded and store.",
    ],
  },
  {
    id: "Standard_Individual",
    title: "Standard Tier",
    price: "49.00",
    buttonText: "Get Started",
    features: [
      "Access to 10 agents for more diverse and in-depth discussions.",
      "6 discussions Faster response times.",
      "20 Document upload and training available.",
      // "Post-discussion summaries for all sessions.",
      "Customizable agent interactions.",
      "30-minute discussion limit per session.",
    ],
  },
];

const enterprisePlans = [
  {
    id: "Starter_Enterprise",
    title: "Starter",
    price: "99.00",
    buttonText: "Get Started",
    features: [
      "All 10 Agents Offer A Comprehensive Range Of Perspectives.",
      "10 Discussions",
      "Priority Response Times.",
      "30 Document Upload And Training Available.",
      // "Post-Discussion Summaries For All Sessions.",
      "Customizable Agent Interactions.",
      // "No Discussion Time Limit—Enjoy Extended Conversations As Needed.",
    ],
  },
  {
    id: "Plus_Enterprise",
    title: "Plus",
    price: "149.99",
    buttonText: "Get Started",
    features: [
      "All 10 Agents Offer A Comprehensive Range Of Perspectives.",
      "20 Discussions Per Month.",
      "Priority Response Times.",
      "40 Document Upload And Training Available.",
      // "Post-Discussion Summaries For All Sessions.",
      "Customizable agent interactions.",
      // "No Discussion Time Limit—Enjoy Extended Conversations As Needed.",
    ],
  },
];

const textBasedPlans = [
  {
    id: "Text_Base_Intraction_Only",
    title: "Text-Based Interactions",
    price: "10.00",
    buttonText: "Get Started",
    features: [
      "Personalized Response",
      "Upload Pdf Document",
      "Upload Image",
      "400k Input Tokens",
      "660k Output Tokens",
      "Personalized Conversations",
      "Task Assistance",
      "Sentiment Analysis",
      "Emotional Supported Response",
      "Fast Response",
      "Scalability And Reliability",
    ],
  },
];

export const AppProvider = ({ children }) => {
  const [personas, setPersonas] = useState([]);
  const [fetchedPersonas, setFetchedPersonas] = useState([]);
  const [check, setCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [test, setTest] = useState(false);
  const [profileData, setProfileData] = useState();
  const [userHasPlan, setUserHasPlan] = useState();
  const [reminderCount, setReminderCount] = useState(0);
  const [time, setTime] = useState({ minute: 0, second: 0 });
  const [formattedTime, setFormattedTime] = useState();
  const [chatType , setChatType] = useState("chose_topic");

  const [recommendedTopic, setRecommendedTopic] = useState([]);
  const [generalTopic, setGeneralTopic] = useState([]);
  const [topicCategory, setTopicCategory] = useState([]);
  const [allPersonas, setAllPersonas] = useState([]);
  const [shareLink, setShareLink] = useState('');
  const [loginFromPath, setLoginFromPath] = useState('');

  const [searchType, setSearchType] = useState('');

  const userId = secureLocalStorage.getItem("userId");
  const usertype = secureLocalStorage.getItem("usertype");

  useEffect(() => {
    const fetchData = async () => {
      // Fetch personas from localStorage after ProfileData completes
      const localPersonas = JSON.parse(localStorage.getItem("personas"));
      console.log("persona array: ", localPersonas);

      if (localPersonas) {
        setPersonas(localPersonas);
      } else {
        setPersonas([]);
      }

      try {
        // Call ProfileData
        await ProfileData();
      } catch (error) {
        console.error("Error in fetchData:", error);
      } 
    };
  
    fetchData();
  }, [userId, usertype]); // Ensure dependencies are properly handled
  

  

  useEffect(() => {
    if (test && userHasPlan && userHasPlan.plan_name !== "Text_Base_Intraction_Only") updateDB();
  }, [test]);
  const logout = () => {
    setProfileData(null);         
    setUserHasPlan(null);         
    setReminderCount(0);          
    setTime({ minute: 0, second: 0 });        
    secureLocalStorage.clear();   
    localStorage.clear();         
    
    console.log("Logout complete: local and secure storage cleared");
};
  const removePersonaCard = (id) => {
    if (personas.length === 1) {
      toast.error("You cannot remove the last persona card.");
      return;
    }
    // console.log("id", id);
    const updatedPersonas = personas.filter((persona, index) => index !== id);
    // console.log("updatedPersonas", updatedPersonas);
    setPersonas(updatedPersonas);
    localStorage.setItem("personas", JSON.stringify(updatedPersonas));
  };

  const setPersonaItems = (items) => {
    // Filter out any undefined items
    const validItems = items.filter(item => item !== undefined);
    
    // Merge the arrays
    const combinedArray = [...validItems, ...personas];
    
    setPersonas(combinedArray);
    localStorage.setItem("personas", JSON.stringify(combinedArray));
  };
  

  const updatePersona = (id, updatedData) => {
    const updatedPersonas = personas.map((persona) =>
      persona.id === id ? { ...persona, ...updatedData } : persona
    );
    setPersonas(updatedPersonas);
    localStorage.setItem("personas", JSON.stringify(updatedPersonas));
  };

  const updateDB = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update_db_feilds`,
        {
          userid: userId,
          time_used: formattedTime,
        }
      );
      // console.log("API call on unmount response:", response.data);
      setTime({ minute: 0, second: 0 });        
      setTest(false)
      setReminderCount(0);
    } catch (error) {
      console.error("Error during API call on unmount:", error);
    }
  };
  const ProfileData = () => {
    setIsLoading(true);
    return new Promise(async (resolve, reject) => {
      const userId = secureLocalStorage.getItem("userId");

      if (!userId) return;

      // const storedProfileData = secureLocalStorage.getItem(`profileData-${userId}`);

      try {
        // console.log("helooooooooo");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/get_profile`,
          {
            params: { user_id: userId , usertype : secureLocalStorage.getItem("usertype")},
            headers: {
              "ngrok-skip-browser-warning": "true",
              Accept: "application/json",
            },
          }
        );
        setProfileData(response.data);
        secureLocalStorage.setItem("profileData", response.data);
        if (response.data.plan) {
          setUserHasPlan(response.data.plan);
        } else {
          setUserHasPlan(false);
        }
        resolve(response);
        if (response.data) {
          setIsLoading(false);
        }
      } catch (err) {
        // setError(err.message);
        // reject(err?.message);
        setIsLoading(false);
      }
    });
  };

  return (
    <AppContext.Provider
      value={{
        recommendedTopic,
        setRecommendedTopic,
        generalTopic,
        setGeneralTopic,
        topicCategory,
        setTopicCategory,
        personas,
        setPersonas,
        removePersonaCard,
        setPersonaItems,
        updatePersona,
        setFetchedPersonas,
        fetchedPersonas,
        setCheck,
        check,
        profileData,
        setProfileData,
        ProfileData,
        setIsLoading,
        isLoading,
        userHasPlan,
        setUserHasPlan,
        logout,
        individualPlans,
        enterprisePlans,
        textBasedPlans,
        setReminderCount,
        reminderCount,
        time,
        setTime,
        formattedTime,
        setFormattedTime,
        updateDB,
        setTest,
        chatType,
        setChatType,
        setSearchType,
        searchType,
        allPersonas,
        setAllPersonas,
        setShareLink,
        shareLink,
        loginFromPath, 
        setLoginFromPath
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
