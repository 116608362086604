import React, { useContext, useEffect, useState } from "react";

import RefreshLogo from "../../assetsNew/image/flat-color-icons_refresh.png";
import Uparrow from "../../assetsNew/image/arrowDown.svg";
import Downarrow from "../../assetsNew/image/arrowUp.svg";
import massageLogo from "../../assetsNew/image/Union.svg";
import massageLogo2 from "../../assetsNew/image/Union2.svg";
import lineImg from "../../assetsNew/image/Line.svg";
import bgImage from "../../assetsNew/image/IndigoDrift.png";
import { AppContext } from "../../Context/AppContext";


function MainCard() {
  const { recommendedTopic = [], setSearchType } = useContext(AppContext);
  const [currentIndex, setCurrentIndex] = useState(0); 

  useEffect(() => {
    if (Array.isArray(recommendedTopic) && recommendedTopic.length > 0) {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % recommendedTopic.length);
        }, 10000);

        return () => clearInterval(interval);
    }
  }, [recommendedTopic]);


  const handleRefresh = () => { 
    setCurrentIndex((prevIndex) => (prevIndex + 1) % recommendedTopic?.length); 
  }; 
  const currentData = recommendedTopic[currentIndex] || {}; 

  const cardStyle = { backgroundImage: `url(${currentData?.image || bgImage})`, opacity: "0.8"}

  // Custom Card hover logic
  useEffect(() => {
      document.querySelectorAll('.hover-card').forEach(card => {
          card.addEventListener('mousemove', (e) => {
              const rect = card.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;
              const centerX = rect.width / 2;
              const centerY = rect.height / 2;
              const rotateX = (y - centerY) / centerY * 10;
              const rotateY = (x - centerX) / centerX * -10;

              card.style.setProperty('--x', `${x}px`);
              card.style.setProperty('--y', `${y}px`);
              card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
          });

          card.addEventListener('mouseleave', () => {
              card.style.transform = 'rotateX(0deg) rotateY(0deg)';
          });
      });
  }, []);


  return (
    <>
      {/* main Landing Page Body  */}
      <div className="container">
        <p className="text-center main-text">Connect with Intelligent Avatars <br />Designed to help you Grow and Learn</p>
      </div>
      <div className="container py-2 main-card">
        <div className="row m-2 d-flex align-items-center justify-content-between flex-sm-column flex-lg-row">
          <div className="left-card col-lg-3" style={cardStyle}>
            <h3>
              {currentData?.topic_title?.slice(0,30)}
            </h3>
            <p className="py-2">
              {currentData?.topic_details}
            </p>
            <button className="mb-1 linear-btn" onClick={handleRefresh}>
              <span>
                Refresh
                <img src={RefreshLogo} alt="" />
              </span>
            </button>
          </div>
          <div className="col-lg-1 align-content-center p-0 arrow-line">
            <img
              src={Uparrow}
              className="uparrow"
              alt="uparrow"
            />
            <img src={Downarrow} alt="downarrow" />
          </div>
          <div className="col-lg-3 middle-card hover-card" id="middle-card-id" onClick={()=>setSearchType('topic')} >
            <div className="d-flex align-items-center justify-content-between ">
              <div className="upper-text">
                <h3>Explore Topics of Interest</h3>
                <p>What Topics Do You Want to Discuss</p>
              </div>
              <div className="card-image pt-3">
                <img src={massageLogo} alt="massage img" width={70} />
              </div>
            </div>
            <img className="line-img" src={lineImg} alt="line img" />
            <div className="lower-text">
              <p>Topic: Career Coaching</p>
              <p>
                Hi! I’m Elle, and here to discuss about career coaching, so
                where you want to start the discussion?
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 middle-card hover-card right-card "
            id="right-card-id"
            onClick={()=>setSearchType('persona')}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="upper-text">
                <h3>Choose your Character</h3>
                <p>Who Do You Want to Chat With</p>
              </div>
              <div className="card-image">
                <img src={massageLogo2} alt="massage img" width={100} />
              </div>
            </div>
            <img className="line-img2" src={lineImg} alt="line img" />
            <div className="lower-text2">
              <p>Persona: Elle</p>
              <p>
                Hi! I’m Elle, and here to discuss about career coaching, so
                where you want to start the discussion?
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Cross button */}
      <div className="cross-button display-none">
        <i className="fa-sharp fa-solid fa-circle-xmark " onClick={()=>setSearchType('')}/>
      </div>
    </>
  );
}

export default MainCard;
