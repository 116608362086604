import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import Navbar from "../../sharedNew/navbar";
import '../../styleNew/login.css';

const Settings = () => {
  const userId = secureLocalStorage.getItem('userId')
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    email: "",
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!formState.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      errors.email = "Email address is invalid";
    }

    if (Object.keys(errors).length > 0) {
      setFormState({
        ...formState,
        errors,
      });
    } else {
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/update_profile`, {
        
          userid: userId ,
          email : formState.email,
          
      })
      .then((res) => {
         toast.success(res || "Email Updated successfully.")
        //  console.log(res);
         navigate("/settings/verify-email" , {state : {formState}})
       
       
      })
      .catch((err) => {
       toast.error(err || "Error updating email , Please try again!");
      });
    }
  };

  return (
    <>
    <Navbar/>
  
  <div className="container z-3">
    <div className="d-flex align-items-center justify-content-center signup-form">
          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            <div className="d-flex flex-column gap-2 emailInput">
              <h4 className="text-light text-center mb-3">Update Email</h4>
              <input
                type="email"
                name="email"
                placeholder="Enter new email"
                value={formState.email}
                onChange={handleInputChange}
                className="modal-input"
              />
              {formState.errors.email && (
                <p className="text-small text-red">{formState.errors.email}</p>
              )}
            </div>
            <div className="w-100 d-flex flex-column gap-1">
              <button type="submit" className="dash-pink-btn fw-bold signInn">
                Continue
              </button>
              <span className="text-small  text-lightgray fw-medium">
                Verification code will be sent to your email
              </span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Settings;
