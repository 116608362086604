import React, { useContext } from "react";

import whatsapplogo from "../assetsNew/image/whatsapplogo.png";
import facebooklogo from "../assetsNew/image/facebooklogo.png";
import instalogo from "../assetsNew/image/instalogo.png";
import redditlogo from "../assetsNew/image/redditLogo.png";
import xlogo from "../assetsNew/image/xlogo.png";
import { AppContext } from "../Context/AppContext";

import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function SharePopUpModal() {
  const { shareLink } = useContext(AppContext);

  
  return (
    <>
      {/* SharePopUp Modal */}
      <div
        className="modal fade"
        id="SharePopUp"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-modal ShareModel text-center">
            <i
              className="fa-sharp fa-solid fa-circle-xmark"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <h3 className="mt-3 mb-4">Share News Via</h3>
            <div className="d-flex align-items-center justify-content-center gap-4 mt-4">
              <WhatsappShareButton url={shareLink} className="d-flex flex-column align-items-center justify-content-center">
                <img src={whatsapplogo} alt="whatsapplogo" />
                <p>Whatsapp</p>
              </WhatsappShareButton>
              <FacebookShareButton url={shareLink} className="d-flex flex-column align-items-center justify-content-center">
                <img src={facebooklogo} alt="facebooklogo" />
                <p>Facebook</p>
              </FacebookShareButton>
              <RedditShareButton url={shareLink} className="d-flex flex-column align-items-center justify-content-center">
                <img src={redditlogo} alt="Redditlogo" />
                <p>Reddit</p>
              </RedditShareButton>
              <TwitterShareButton url={shareLink} className="d-flex flex-column align-items-center justify-content-center">
                <img src={xlogo} alt="xlogo" />
                <p>Twitter</p>
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SharePopUpModal;
