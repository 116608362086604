import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "../../sharedNew/navbar";
import '../../styleNew/login.css';

const Updatepswd = () => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem("userId");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [formState, setFormState] = useState({
    currentPassword: "",
    newPassword: "",
    reEnterPassword: "",
    errors: {},
  });

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      errors: {
        ...formState.errors,
        [name]: "", // Reset error message for the field
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,14}$/;

    if (!formState.currentPassword) {
      errors.currentPassword = "This field is required";
    }

    if (!formState.newPassword) {
      errors.newPassword = "This field is required";
    } else if (!passwordRegex.test(formState.newPassword)) {
      errors.newPassword =
        "Password must be 8-14 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    if (!formState.reEnterPassword) {
      errors.reEnterPassword = "This field is required";
    } else if (formState.newPassword !== formState.reEnterPassword) {
      errors.reEnterPassword = "Passwords do not match";
    }

    if (Object.keys(errors).length > 0) {
      setFormState({
        ...formState,
        errors,
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/update_profile`, {
          userid: userId,
          current_password: formState.currentPassword,
          new_password: formState.newPassword,
          confirm_password: formState.reEnterPassword,
        })
        .then((res) => {
          toast.success(res?.response?.data || "Password updated successfully");
          // console.log(res);
          navigate("/settings");
        })
        .catch((err) => {
          toast.error(err?.response?.data || "Issue in updating password , Try again ! ");
        });
    }
  };

  return (
    <>
    <Navbar/>
  
    <div className="container z-3">
      <div className="d-flex align-items-center justify-content-center signup-form">
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <h4 className="text-center text-light">Update Password</h4>
          <div className="d-flex flex-column gap-0 mt-2 emailInput">
            <div className="setting-pswd-input emailInput">
              <label className="text-small fw-normal">Current Password</label>
              <input
                type={showPassword1 ? "text" : "password"}
                name="currentPassword"
                placeholder="************"
                value={formState.currentPassword}
                onChange={handleInputChange}
                className="modal-input"
                style={{width: "90%"}}
              />
              <Icon
                icon={showPassword1 ? "fa-solid:eye" : "fa6-solid:eye-slash"}
                className="eye-icon-setting cursor-pointer ms-1"
                onClick={togglePasswordVisibility1}
                color="#959595"
              />
            </div>
            {formState.errors.currentPassword && (
              <p className="text-small text-red mt-1 error-msgs">
                {formState.errors.currentPassword}
              </p>
            )}
          </div>
          <div className="d-flex flex-column gap-0 mt-2 emailInput">
            <label className="text-small fw-normal">Update Password</label>
            <div className="setting-pswd-input">
              <input
                type={showPassword2 ? "text" : "password"}
                name="newPassword"
                placeholder="************"
                value={formState.newPassword}
                onChange={handleInputChange}
                className="modal-input"
                style={{width: "90%"}}
              />
              <Icon
                icon={showPassword2 ? "fa-solid:eye" : "fa6-solid:eye-slash"}
                className="eye-icon-setting cursor-pointer ms-1"
                onClick={togglePasswordVisibility2}
                color="#959595"
              />
            </div>
            {formState.errors.newPassword && (
              <p className="text-small text-red mt-1 error-msgs">
                {formState.errors.newPassword}
              </p>
            )}
          </div>
          <div className="d-flex flex-column gap-0 mt-2 emailInput">
            <label className="text-small fw-normal">Re Enter Password</label>
            <div className="setting-pswd-input">
              <input
                type={showPassword3 ? "text" : "password"}
                name="reEnterPassword"
                placeholder="************"
                value={formState.reEnterPassword}
                onChange={handleInputChange}
                className="modal-input"
                style={{width: "90%"}}
              />
              <Icon
                icon={showPassword3 ? "fa-solid:eye" : "fa6-solid:eye-slash"}
                className="eye-icon-setting cursor-pointer ms-1"
                onClick={togglePasswordVisibility3}
                color="#959595"
              />
            </div>
            {formState.errors.reEnterPassword && (
              <p className="text-small text-red mt-1 error-msgs">
                {formState.errors.reEnterPassword}
              </p>
            )}
          </div>
          <button type="submit" className="dash-pink-btn fw-bold signInn">
            SAVE NEW CHANGES
          </button>
        </form>
      </div>
    </div>
    </>
  );
};

export default Updatepswd;
