import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import recodingImg from '../../assetsNew/image/meeting/recordIcon.png';
import PencilSimpleLine from '../../assetsNew/image/meeting/PencilSimpleLine.png';
import HeartLogo from '../../assetsNew/image/meeting/Heart.png';
import LikedLogo from '../../assetsNew/image/meeting/Liked.png';
import TrashSimpleLogo from '../../assetsNew/image/meeting/TrashSimple.png';
import PlusCircleLogo from '../../assetsNew/image/meeting/PlusCircle.png';
import animojiLogo from '../../assetsNew/image/meeting/animoji.png';
import muteIcon from '../../assetsNew/image/meeting/mute.png';
import callIcon from '../../assetsNew/image/meeting/call.png';
import micIcon from '../../assetsNew/image/meeting/mic.png';
import muteMicIcon from '../../assetsNew/image/meeting/offMic.png';
import speekerIcon from '../../assetsNew/image/meeting/UnmuteMic.png';
import attachIcon from '../../assetsNew/image/meeting/ion_attach.png';
import rightArrowIcon from '../../assetsNew/image/meeting/rightArrow.png';
import LineLogo from '../../assetsNew/image/Line.svg';

import businessmanLogo9 from '../../assetsNew/image/businessman/businessman9.png';

import AddPersonaModal from "../../modalsNew/AddPersonaModal";
import EditPersonaModal from "../../modalsNew/EditPersonaModal";
import FavoritePersonaModal from "../../modalsNew/FavoritePersonaModal";
import RemovePersonaModal from "../../modalsNew/RemovePersonaModal";
import secureLocalStorage from "react-secure-storage";

import { Icon } from "@iconify/react";
import { toast } from "react-toastify";

import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import animationData from "../../spinner.json";
import Lottie from "lottie-react";

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import axios from "axios";
import {Tooltip} from 'bootstrap';
import { AppContext } from "../../Context/AppContext";
import OnlySignInUpModal from "../../modalsNew/OnlySignInUpModal";
import { useLocation, useNavigate } from "react-router-dom";

import { ThreeDots } from 'react-loader-spinner';

// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function MeetingContaint() {
  // const location = useLocation();
  const navigate = useNavigate();

  const {
    profileData,
    personas, 
    chatType,
    setCheck,
    userHasPlan,
    setReminderCount,
    time,
    setTime,
    formattedTime,
    setFormattedTime,
    setTest,
    reminderCount,
    setUserHasPlan,
    setSearchType
  } = useContext(AppContext);

  const [isAnimationLoading, setIsAnimationLoading] = useState(false);

  const [addPopUpShow, setAddPopUpShow] = useState(false);
  const [customAddPopUpShow, setCustomAddPopUpShow] = useState(false);
  const [editPopUp, setEditPopUp] = useState(false);
  const [likePopUp, setLikePopUp] = useState(false);
  const [signInUpPopUp, setSignInUpPopUp] = useState(false);

  const handleSignInUpPopUpClose = useCallback(() => setSignInUpPopUp(false), []);
  const handleSignInUpPopUpShow = useCallback(() => setSignInUpPopUp(true), []);

  const handleLikePopUpClose = useCallback(() => setLikePopUp(false), []);
  const handleLikePopUpShow = useCallback(() => setLikePopUp(true), []);
  const handleEditPopUpClose = useCallback(() => setEditPopUp(false), []);
  const handleEditPopUpShow = useCallback(() => setEditPopUp(true), []);
  const handleAddPopUpClose = useCallback(() => setAddPopUpShow(false), []);

  const handleAddPopUpShow = () => {
    if(!userprofileData){
      // toast.error("Please login to start your discussion...");
      handleSignInUpPopUpShow();
      return;
    }

    if (!userHasPlan?.plan_name) {
      toast.error("Please select a plan to start your discussion...");
      // setUserHasPlan('Free_Trial');
      navigate('/pricing-plans');
    } else {
      setAddPopUpShow(true);
    }
  };

  const handleCustomClose = useCallback(() => setCustomAddPopUpShow(false), []);
  const handleCustomShow = useCallback(() => setCustomAddPopUpShow(true), []);

  const [isLoading, setIsLoading] = useState(false);
  const userprofileData = secureLocalStorage.getItem("profileData");
  const userId = secureLocalStorage.getItem("userId");
  const [personaOne, setPersonaOne] = useState();

  // const userType = secureLocalStorage.getItem("usertype");

  useEffect(()=>{
    setSearchType('');
  },[])
  
  const topics = [
    { topic_title: "Startup Strategy Planning", topic_details: "An essential guide to developing strategies that set the foundation for a successful startup, covering key aspects like vision, goals, and execution." },
    { topic_title: "Business Model Validation", topic_details: "Learn how to test and refine your business model to ensure its viability and profitability in real-world markets." },
    { topic_title: "Funding & Investment", topic_details: "Discover strategies for securing funding and investment to fuel your business growth, from pitching to managing investor relations." },
    { topic_title: "Growth Hacking Techniques", topic_details: "Explore innovative techniques to accelerate business growth through marketing, customer acquisition, and retention strategies." },
    { topic_title: "Customer Acquisition", topic_details: "Understand the best practices and strategies for acquiring new customers and expanding your market reach." },
    { topic_title: "Pricing Strategy", topic_details: "A deep dive into pricing strategies that optimize profitability while remaining competitive in the market." },
    { topic_title: "Market Entry Planning", topic_details: "Learn how to plan for successful market entry, considering competition, customer needs, and local regulations." },
    { topic_title: "Competition Analysis", topic_details: "Understand the importance of competitive analysis and how to use it to position your product or service in the market." },
    { topic_title: "Lesson Planning Tips", topic_details: "Effective techniques for designing lesson plans that engage students and enhance learning outcomes." },
    { topic_title: "Student Engagement Strategies", topic_details: "Explore strategies to keep students motivated and engaged in the learning process, from interactive activities to feedback techniques." },
    { topic_title: "Classroom Management", topic_details: "Techniques for managing a classroom effectively, creating an environment conducive to learning and minimizing disruptions." },
    { topic_title: "Digital Teaching Tools", topic_details: "Discover a variety of digital tools that can support online and in-person teaching, improving student engagement and collaboration." },
    { topic_title: "Assessment Methods", topic_details: "A guide to various assessment methods that help evaluate student performance and ensure learning goals are met." },
    { topic_title: "Special Education Approaches", topic_details: "Learn about different strategies for teaching students with special needs, including personalized support and adapted teaching methods." },
    { topic_title: "Parent Communication", topic_details: "Effective communication strategies for keeping parents informed about their child's progress and fostering positive relationships." },
    { topic_title: "Patient Care Best Practices", topic_details: "Essential practices for providing high-quality patient care, including effective communication, treatment strategies, and patient safety." },
    { topic_title: "Healthcare Management", topic_details: "Explore the fundamentals of healthcare management, including operational efficiency, leadership, and regulatory compliance." },
    { topic_title: "Medical Ethics Discussions", topic_details: "Analyze complex ethical issues in the healthcare sector, including patient rights, privacy, and end-of-life decisions." },
    { topic_title: "Team Coordination", topic_details: "Learn how to improve team coordination within healthcare settings to ensure optimal patient outcomes and efficient workflows." },
    { topic_title: "Healthcare Technology Updates", topic_details: "Stay updated on the latest advancements in healthcare technology and how they are transforming patient care and hospital management." },
    { topic_title: "Patient Communication", topic_details: "Strategies for improving communication with patients to enhance trust, treatment adherence, and patient satisfaction." },
    { topic_title: "Work-Life Balance in Healthcare", topic_details: "Techniques for managing the demanding nature of healthcare jobs while maintaining personal well-being and work-life balance." },
    { topic_title: "Medical Research Analysis", topic_details: "Guidance on analyzing and interpreting medical research to stay informed on emerging trends and treatments." },
    { topic_title: "Dating Advice & Tips", topic_details: "Expert advice on navigating the world of dating, from first dates to building meaningful relationships." },
    { topic_title: "Building Better Relationships", topic_details: "Insights into creating and maintaining strong, healthy relationships through communication, trust, and mutual respect." },
    { topic_title: "Communication Skills", topic_details: "Learn the essential communication skills necessary for building and maintaining successful personal and professional relationships." },
    { topic_title: "Conflict Resolution", topic_details: "Strategies for resolving conflicts in relationships, from identifying root causes to finding constructive solutions." },
    { topic_title: "Long-distance Relationship Strategies", topic_details: "Effective methods for maintaining a strong, healthy long-distance relationship, including communication techniques and trust-building." },
    { topic_title: "Understanding Love Languages", topic_details: "Explore the five love languages and how understanding your partner's can help strengthen your relationship." },
    { topic_title: "Setting Healthy Boundaries", topic_details: "Learn how to establish and maintain healthy boundaries in relationships to foster mutual respect and understanding." },
    { topic_title: "Marriage Preparation", topic_details: "Guidance on preparing for marriage, including communication strategies, financial planning, and mutual goals." },
    { topic_title: "Trend Analysis & Forecasting", topic_details: "Learn how to analyze fashion trends and forecast future styles using data, intuition, and market research." },
    { topic_title: "Sustainable Fashion", topic_details: "A look into the growing movement of sustainable fashion, from eco-friendly materials to ethical production practices." },
    { topic_title: "Collection Development", topic_details: "Understanding the process of developing a fashion collection, from concept to production, while maintaining a cohesive theme." },
    { topic_title: "Fashion Business Strategy", topic_details: "Explore business strategies specific to the fashion industry, including brand development, marketing, and financial management." },
    { topic_title: "Material Sourcing", topic_details: "A guide to sourcing materials for fashion design, focusing on sustainability, quality, and cost-effectiveness." },
    { topic_title: "Design Portfolio Review", topic_details: "Learn how to review and improve a fashion design portfolio to showcase your creativity and skills to potential employers or clients." },
    { topic_title: "Brand Development", topic_details: "Techniques for developing a strong and recognizable fashion brand, from identity to marketing and customer loyalty." },
    { topic_title: "Fashion Marketing", topic_details: "Strategies for effectively marketing fashion products, including social media campaigns, influencer collaborations, and targeted advertising." },
    { topic_title: "Child Development Stages", topic_details: "An overview of the key stages of child development, including cognitive, emotional, and physical milestones." },
    { topic_title: "Parenting Techniques", topic_details: "Effective parenting strategies to support children's growth, from discipline methods to fostering emotional intelligence." },
    { topic_title: "Education Planning", topic_details: "Guidance on planning for your child's education, from early childhood to higher education, including academic and extracurricular activities." },
    { topic_title: "Work-Life Balance", topic_details: "Learn how to balance your career, family life, and personal well-being to reduce stress and increase productivity." },
    { topic_title: "Children's Health", topic_details: "Essential information on maintaining your child's health, including nutrition, exercise, and addressing common health issues." },
    { topic_title: "Family Activities", topic_details: "Ideas for fun and educational family activities that strengthen relationships and create lasting memories." },
    { topic_title: "Teen Communication", topic_details: "Techniques for improving communication with teenagers, addressing challenges, and fostering mutual respect." },
    { topic_title: "Child Behavior Management", topic_details: "Learn effective strategies for managing challenging behaviors in children, including positive reinforcement and behavior modification." },
    { topic_title: "Resume Building", topic_details: "Practical tips and strategies for building a strong resume that effectively showcases your skills and experience." },
    { topic_title: "Career Path Planning", topic_details: "Steps for mapping out a successful career path, considering your goals, strengths, and opportunities." },
    { topic_title: "Job Search Strategy", topic_details: "A comprehensive guide to job search strategies, including how to target the right roles and maximize your chances of success." },
    { topic_title: "Salary Negotiation", topic_details: "Learn how to negotiate your salary effectively, ensuring you're paid what you're worth based on your experience and industry standards." },
    { topic_title: "LinkedIn Optimization", topic_details: "Techniques for optimizing your LinkedIn profile to attract recruiters, showcase your skills, and expand your professional network." },
    { topic_title: "Industry Transitions", topic_details: "Strategies for making successful transitions between industries, including skill adaptation and networking." },
    { topic_title: "Remote Work Success", topic_details: "Tips for thriving in a remote work environment, from setting up your workspace to maintaining work-life balance." },
    { topic_title: "Professional Networking", topic_details: "The importance of professional networking and strategies to build valuable connections that benefit your career." },
    { topic_title: "Study Techniques", topic_details: "Proven study techniques to improve retention and understanding, from active recall to spaced repetition." },
    { topic_title: "Memory Improvement", topic_details: "Tips and exercises for improving memory and cognitive function, aiding in learning and information recall." },
    { topic_title: "Time Management", topic_details: "Effective time management strategies to optimize productivity and balance academic, personal, and professional commitments." },
    { topic_title: "Note-Taking Methods", topic_details: "Explore different note-taking techniques, including Cornell Notes and mind mapping, to enhance learning and retention." },
    { topic_title: "Learning Style Assessment", topic_details: "Learn about different learning styles and how to assess and cater to your personal learning preferences." },
    { topic_title: "Concentration Tips", topic_details: "Practical tips for improving concentration and staying focused during study sessions or work tasks." },
    { topic_title: "Exam Preparation", topic_details: "Effective methods for preparing for exams, including time management, practice tests, and stress reduction techniques." },
    { topic_title: "Self-Paced Learning", topic_details: "How to structure and succeed in self-paced learning environments, from setting goals to staying motivated." },
    { topic_title: "Language Learning Methods", topic_details: "An overview of various language learning methods, from immersive experiences to online tools and apps." },
    { topic_title: "Conversation Practice", topic_details: "Tips and techniques for practicing conversation in a new language to improve fluency and confidence." },
    { topic_title: "Grammar Mastery", topic_details: "Master the rules of grammar in your target language to enhance your ability to communicate clearly and correctly." },
    { topic_title: "Vocabulary Building", topic_details: "Strategies for building a strong vocabulary in a new language, including flashcards and context-based learning." },
    { topic_title: "Cultural Understanding", topic_details: "The importance of understanding the culture behind a language to communicate more effectively and meaningfully." },
    { topic_title: "Pronunciation Tips", topic_details: "Techniques for improving pronunciation and mastering difficult sounds in a new language." },
    { topic_title: "Language Immersion", topic_details: "The benefits of language immersion and how to integrate it into your learning process for faster results." },
    { topic_title: "Learning Resources", topic_details: "A guide to the best resources for learning a new language, from textbooks to online courses and mobile apps." },
    { topic_title: "Interview Preparation", topic_details: "How to prepare for an interview, including researching the company, practicing answers, and dressing appropriately." },
    { topic_title: "Common Questions & Answers", topic_details: "A guide to the most common interview questions and how to answer them effectively to make a strong impression." },
    { topic_title: "Body Language Tips", topic_details: "Learn how to use body language to convey confidence and professionalism during an interview." },
    { topic_title: "Virtual Interview Success", topic_details: "Strategies for succeeding in virtual interviews, including technical preparation and maintaining engagement." },
    { topic_title: "Technical Interview Prep", topic_details: "How to prepare for technical interviews, including coding challenges, problem-solving, and demonstrating your skills." },
    { topic_title: "Behavioral Interview Practice", topic_details: "Prepare for behavioral interview questions using the STAR method to highlight your achievements and problem-solving abilities." },
    { topic_title: "Follow-up Strategies", topic_details: "Learn how to follow up after an interview, including how to write a thank-you note and maintain professionalism." },
    { topic_title: "Confidence Building", topic_details: "Techniques for building self-confidence, especially in high-pressure situations like interviews or presentations." },
    { topic_title: "Goal Setting & Planning", topic_details: "Effective goal setting and planning strategies to achieve personal and professional success." },
    { topic_title: "Personal Finance", topic_details: "Fundamentals of managing your personal finances, including budgeting, saving, and investing." },
    { topic_title: "Work-Life Balance", topic_details: "Learn how to balance your career, personal life, and well-being to reduce stress and improve overall happiness." },
    { topic_title: "Decision Making", topic_details: "Techniques for making informed and confident decisions, both personally and professionally." },
    { topic_title: "Stress Management", topic_details: "Explore methods to manage and reduce stress, including mindfulness, time management, and relaxation techniques." },
    { topic_title: "Personal Growth", topic_details: "Strategies for fostering personal growth, including self-reflection, continuous learning, and embracing challenges." },
    { topic_title: "Habit Formation", topic_details: "Understand the science of habit formation and how to create positive habits that lead to long-term success." },
    { topic_title: "Life Transitions", topic_details: "Learn how to navigate significant life transitions, such as career changes or personal milestones, with confidence." },
    { topic_title: "Product Strategy Review", topic_details: "Review and refine your product strategy to ensure it aligns with market needs and company goals." },
    { topic_title: "Market Analysis", topic_details: "Conduct a comprehensive market analysis to understand trends, customer needs, and opportunities for growth." },
    { topic_title: "Business Plan Feedback", topic_details: "How to gather and implement feedback on your business plan to increase its chances of success." },
    { topic_title: "Competitor Research", topic_details: "Learn how to conduct competitor research to gain valuable insights into market trends and identify gaps in the industry." },
    { topic_title: "Brand Positioning", topic_details: "Techniques for positioning your brand effectively in the market to attract and retain customers." },
    { topic_title: "Creative Writing Ideas", topic_details: "Brainstorm creative writing prompts and techniques to inspire your next writing project." },
    { topic_title: "Content Strategy", topic_details: "Develop a content strategy that aligns with your brand's goals, from blogs to social media posts." },
    { topic_title: "Marketing Campaign Brainstorm", topic_details: "Generate creative ideas for your next marketing campaign, from product launches to seasonal promotions." },
    { topic_title: "Design Feedback", topic_details: "How to receive and give constructive design feedback to improve the quality of your projects." },
    { topic_title: "Innovation Workshop", topic_details: "How to run an innovation workshop that fosters creativity and generates new ideas for your business." },
    { topic_title: "Career Planning", topic_details: "Plan for your future career by setting clear goals, networking, and developing skills for long-term success." },
    { topic_title: "Leadership Skills", topic_details: "Learn essential leadership skills, from communication to decision-making, that inspire and motivate teams." },
    { topic_title: "Public Speaking Tips", topic_details: "Tips for becoming a more confident and effective public speaker, whether in small meetings or large audiences." },
    { topic_title: "Interview Preparation", topic_details: "Thorough preparation for interviews, including how to research the company, anticipate questions, and present your best self." },
    { topic_title: "Professional Growth", topic_details: "Strategies for continuous professional development, including networking, education, and self-improvement." },
    { topic_title: "Industry Trends Analysis", topic_details: "How to analyze industry trends and use them to drive innovation and business growth." },
    { topic_title: "Data Interpretation", topic_details: "Techniques for interpreting complex data to make informed business decisions." },
    { topic_title: "Research Planning", topic_details: "A guide to planning research projects, from developing hypotheses to collecting and analyzing data." },
    { topic_title: "Survey Analysis", topic_details: "Learn how to analyze survey results to derive actionable insights for decision-making." },
    { topic_title: "Academic Paper Review", topic_details: "How to critically review academic papers and research to assess their validity and relevance." },
    { topic_title: "Youtube Video ideas", topic_details: "Creative ideas for engaging and valuable Youtube videos, including trending topics and evergreen content." },
    { topic_title: "Superfoods and Their Benefits", topic_details: "Explore the health benefits of various superfoods and how to incorporate them into your diet." },
    { topic_title: "Fitness Routines", topic_details: "Effective fitness routines for different goals, from weight loss to building muscle and improving endurance." },
    { topic_title: "Travel Planning for Groups", topic_details: "Tips for planning group travel, including accommodation, activities, and managing different preferences." },
    { topic_title: "Tourism Marketing Strategies", topic_details: "Marketing strategies for the tourism industry to attract visitors and increase engagement." },
    { topic_title: "Event Planning Best Practices", topic_details: "Best practices for planning successful events, from logistics to guest engagement." },
    { topic_title: "Virtual Event Management", topic_details: "Techniques for managing virtual events, ensuring smooth operations and interactive experiences." },
    { topic_title: "Personal Brand Online Building", topic_details: "Strategies for building and maintaining a strong personal brand online through social media and content creation." },
    { topic_title: "Meal Prep Ideas", topic_details: "Creative and healthy meal prep ideas for easy, nutritious meals throughout the week." },
    { topic_title: "Plant-Based Diets", topic_details: "Explore the benefits of plant-based diets and tips for transitioning to a vegan or vegetarian lifestyle." },
    { topic_title: "Nutrition for Weight Loss", topic_details: "Essential nutrition tips for effective weight loss, including portion control and healthy eating habits." }
  ];
  const randomTopic = ()=>{
    const index = Math.floor(Math.random() * topics.length);
    const selectedTopic = topics[index];

    if (selectedTopic) {
      localStorage.setItem("topic", selectedTopic.topic_title);
      return selectedTopic.topic_title;
    }else{
      return 'Startup Strategy Planning';
    }
  }
  const topic = localStorage.getItem("topic") || randomTopic();

  const hasTextBasedPlan = userHasPlan?.plan_name === "Text_Base_Intraction_Only";

  const handleEditePersona = (persona) =>{
    setPersonaOne(persona);
    handleEditPopUpShow();
  }

  useEffect(() => { 
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')); 
    const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl) =>  new Tooltip(tooltipTriggerEl));
  }, []);

  // useEffect(()=>{
  //   if(!userprofileData){
  //     navigate('/signin');
  //   }
  // },[userprofileData])



//============ Chart Section Start =============

  // const [isendModal, setisendModal] = useState(false);
  // const [isGuestModal, setisGuestModal] = useState(false);
  const [isSpeakerMuted, setIsSpeakerMuted] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [tempMessage, setTempMessage] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [recognizer, setRecognizer] = useState(null);
  const [streamText, setStreamText] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [sessionId, setSessionId] = useState();

  const [startConversetion, setstartConversetion] = useState(false);
  const [statChart, setStatChart] = useState(false);

  const audioRef = useRef(null);
  const fileInputRef = useRef(null);
  const connection = useRef(null);
  const isSpeakerMutedRef = useRef(false);
  const chatContainerRef = useRef(null);
  const tempMessageRef = useRef([]);
  const streamTextRef = useRef(streamText);

  const timeRef = useRef({ minute: 0, second: 0 });

  let variable = "";
  let audioQueue = [];
  let socket;
  let meetingEndTime;
  let warningTime;

  if (userHasPlan?.plan_name === "Free_Trial") {
    meetingEndTime = "15:00";
    warningTime = "10:00";
  } else {
    meetingEndTime = "30:00";
    warningTime = "25:00";
  }

  // const {
  //   transcript,
  //   browserSupportsSpeechRecognition,
  //   resetTranscript
  // } = useSpeechRecognition();

  // if (!browserSupportsSpeechRecognition) {
  //   toast.error("Your Browser doesn't support speech recognition.");
  // }

  // const newStartListening = () => {
  //   audioRef.current.muted = true;
  //   resetTranscript();
  //   setIsListening(true);
  //   SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  // }

  // const newStopListening = () => {
  //   SpeechRecognition.stopListening();
  //   setIsListening(false);
  // }

  // useEffect(()=>{
  //   setChatInput(transcript);
  // },[transcript]);

  //-------- Start Meeting Function ------------------------

    //Main Disscussion Funtion
    const handleDisccussion = () => {
      if(!userprofileData){
        // toast.error("Please login to start your discussion...");
        handleSignInUpPopUpShow();
        return;
      }
      if(!userHasPlan.plan_name){
        toast.error("Please select a plan to start your discussion...");
        navigate('/pricing-plans');
        return;
      }

      setIsLoading(true)
      
      const personaData = personas?.map((persona) => persona.name);
      // console.log(personaData);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/conversation/start_discussion`, {
          username: profileData.username ,
          userid: userId,
          personas: personaData,
          topic_details: topic,
          chat_type: chatType
        })
        .then((res) => {
          // console.log("session_id: ",res?.data?.session_id);
          setSessionId(res?.data?.session_id);
          toast.success("Please wait while we start your discussion...");
          setstartConversetion(true);
          setStatChart(prev => !prev);
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err || "Error starting discussion , Please try again!");
        }).finally(() => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      if (!startConversetion || !userHasPlan?.plan_name) return;

      // const firstPersona = personas[0]?.name;

      socket = new WebSocket(
        `wss://slara.onrender.com/ws/${userId}/${sessionId}/${userHasPlan.plan_name}`
      );
  
      let isPlaying = false;
      let currentResponseId;
  
      const playAudioQueue = () => {
        if (audioQueue.length > 0 && !isPlaying) {
          isPlaying = true;
          const { audioUrl, responseId } = audioQueue.shift();
  
          if (responseId !== currentResponseId) {
            isPlaying = false;
            playAudioQueue();
            return;
          }
          const audio = new Audio(audioUrl);
          audioRef.current = audio;
  
          if (isSpeakerMutedRef.current) {
            audioRef.current.muted = true;
            audio.muted = true;
          }
          audio.onended = () => {
            isPlaying = false;
            if (audioQueue.length === 0) {
              setActiveSpeaker(null);
            }
            playAudioQueue();
          };
  
          audio.play().catch((e) => {
            // console.log("Error playing audio", e);
            isPlaying = false;
            if (audioQueue.length === 0) {
              setActiveSpeaker(null);
            }
            playAudioQueue();
          });
        }
      };
  
      socket.addEventListener("open", (event) => {
        console.log("Socket Connected");
      });
  
      socket.addEventListener("message", async (event) => {
        // console.log("socket data from server", event.data);
  
        if (event.data instanceof Blob) {
          try {
            // console.log("Received Blob:", event.data);
  
            const audioUrl = URL.createObjectURL(event.data);
            // download(audioUrl)
            audioQueue.push({ audioUrl, responseId: currentResponseId });
  
            if (!isPlaying) {
              playAudioQueue();
            }
          } catch (error) {
            console.error("Error handling Blob data:", error);
          }
        } else {
          let data;
          try {
            data = JSON.parse(event.data);
            if (data.event === "meeting_reminder") {
              // console.log("Meeting Reminder");
              setReminderCount((prevReminderCount) => prevReminderCount + 1);
              // if (reminderCount > 3) {
              //   navigate("/dashboard/feedback", {
              //     state: { personas: continuedPersonas, sessionId },
              //   });
              // }
            } else if (data.event === "token_used") {
              toast.error("You have used all your time. Please renew your plan.");
              onCallEndClick();
              // navigate("/dashboard/feedback", {
              //   state: { personas: continuedPersonas, sessionId },
              // });
            } else if (data.event === "meeting_reminder_streaming_end") {
              // console.log("Meeting Reminder Streaming End");
            } else {
              // console.log("Other event then Meeting reminder");
              setReminderCount(0);
            }
            if (data.event === "task_cancel") {
              audioQueue = [];
              variable = "";
              return;
            }
            if (data.response_id) {
              // console.log("Current Response ID:", currentResponseId);
              currentResponseId = data.response_id;
            }
            if (data.respond_by) {
              setActiveSpeaker(data.respond_by);
            }
            if (data.text) {
              // const responseText = data.text;
              const responseText = data.text.replace(/Id say /, "");
              variable += responseText;
            }
            const getImageUrl = () => {
              switch (data.respond_by) {
                case "Chat Lead":
                  return personas[0]?.image || "./businessman9.png";
  
                default:
                  const persona = personas.find(
                    (persona) => persona.name === data.respond_by
                  );
  
                  if (persona) {
                    return persona.image ? persona.image : "./businessman9.png";
                  }
                  return "./businessman9.png";
              }
            };
            const botResponse = {
              sender: data.respond_by === 'Chat Lead' ? personas[0]?.name : data.respond_by,
              text: variable,
              time: new Date().toLocaleTimeString(),
              image: getImageUrl(),
            };
            // console.log("Marked Text", botResponse.text);
            setStreamText((prevStreamText) => {
              const updatedStreamText = [botResponse];
              // console.log(updatedStreamText);
              streamTextRef.current = updatedStreamText;
              if (!data.text) {
                if (hasTextBasedPlan) {
                  setActiveSpeaker(null);
                }
                if (hasTextBasedPlan) {
                  setActiveSpeaker(null);
                }
                const botText = updatedStreamText?.map((text) => text);

                setIsAnimationLoading(false);

                setChatHistory((chatHistoryPrev) => [
                  ...chatHistoryPrev,
                  ...botText,
                ]);
                variable = "";
                return [];
              }
  
              return updatedStreamText;
            });
            // console.log(tempMessage);
            setIsAnimationLoading(false);
            
            setChatHistory((prev) => [...prev, ...tempMessageRef.current]);
            setTempMessage([]);
            if (data.voice) {
              // console.log("Received voice data:", data.voice);
              try {
                const base64Audio = data.voice;
                const binaryString = atob(base64Audio);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                  bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: "audio/wav" });
                const audioUrl = URL.createObjectURL(blob);

                //remove old audio
                
                audioQueue = [];

                audioQueue.push({ audioUrl, responseId: currentResponseId });
                playAudioQueue();
              } catch (error) {
                console.error("Error handling audio chunk:", error);
              }
            }
          } catch (error) {
            console.error("Error parsing message data:", error);
          }
        }
      });
  
      connection.current = socket;
  
      return () => {
        audioQueue = [];
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          audioRef.current = null;
        }
        socket.close();
        setTest(true);
      };
    }, [statChart]);

  //  -----------------------

  //Format the time
  const formatTimeTotal = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  //Calculate formatTimeTotal
  const remainingTimeInSeconds = userHasPlan?.time_total - userHasPlan?.time_used;
  const formattedRemainingTime = formatTimeTotal(remainingTimeInSeconds);

  //Checking session time is over or not
  //All time checking here
  useEffect(() => {
      if (!startConversetion) return;
      if (formattedTime === formattedRemainingTime) {
        toast.error("You have used all your time. Please renew your plan.");
        setTest(true);
        setstartConversetion(false);
        onCallEndClick();
        if (profileData?.username === "Guest") {
          secureLocalStorage.setItem("time", formattedTime);
          setTime({ minute: 0, second: 0 });
          // socket.close();
          // setisGuestModal(true);
        } else {
            navigate('/pricing-plans');
          // navigate("/dashboard/feedback", {
          //   state: { personas: continuedPersonas, sessionId },
          // });
        }
      }
      if (userHasPlan?.plan_name === "Free_Trial") {
        meetingEndTime = "15:00";
        warningTime = "10:00";
      } else {
        meetingEndTime = "30:00";
        warningTime = "25:00";
      }

      const firstPersona = personas[0]?.name;
    
      if (formattedTime === meetingEndTime) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/conversation/meeting_end`, {
            userid: userId,
            sessionid: sessionId,
            plan: userHasPlan.plan_name,
          })
          .then((response) => {
            // console.log(response.data);
          })
          .catch((error) => {
            console.error("Error sending message:", error);
          })
          .finally(()=>{
            setstartConversetion(false);
            onCallEndClick();
          });
      }
    
      if (formattedTime === warningTime) {
        toast.warn("Discussion Time is about to End...");
      }
    
      if (formattedTime === meetingEndTime) {
        if (connection.current) {
          connection.current.close(); 
          console.log("Socket Close");
          connection.current = null;
        }
        
        // updateDB();
        setCheck(false);

        setTest(true);
        setstartConversetion(false);
        onCallEndClick();
        // navigate("/dashboard/feedback", {
        //   state: { personas: continuedPersonas, sessionId },
        // });
      }

  }, [formattedTime, formattedRemainingTime, statChart, startConversetion]);

  //Setting time used
  useEffect(() => {
    if (!startConversetion) return;
    setFormattedTime(
      `${String(time.minute).padStart(2, "0")}:${String(time.second).padStart(
        2,
        "0"
      )}`
    );

  }, [time]);

  //Count reminder
  useEffect(() => {
    if (!startConversetion) return;
    if (reminderCount > 3) {
      // console.log("reminder 3 done")
      setstartConversetion(false);
      onCallEndClick();
    }
    
  }, [reminderCount]);

  //handle chat scroll
  useEffect(() => {
    if (!startConversetion) return;
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, tempMessage, streamText]);

  //Checking person is active or not in chart discussion
  useEffect(() => {
    if (!startConversetion) return;
    const firstPersona = personas[0]?.name;
    const timer = setTimeout(() => {
      // console.log("10 seconds has passed but no response");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/conversation/meeting_reminder`,
          {
            userid: userId,
            sessionid: sessionId,
            plan: userHasPlan.plan_name,
          }
        )
        .then((res) => {
          // console.log(res.data);
          // setReminderCount((prevCount) => prevCount + 1);
        })
        .catch((err) => {
          console.error("Error sending message:", err);
        });
    }, 60000);
    return () => clearTimeout(timer);
  }, [chatHistory, startConversetion]);

  //Wellcome text and Start count time
  // useEffect(() => {
  //   if (!startConversetion) return;

  //   if (!hasTextBasedPlan) {
  //     toast.info(
  //       "Kindly unmute your mic and speaker to participate in the meeting."
  //     );
  //   }
  //   setCheck(true);

  //   const interval = setInterval(() => {
  //     setTime((prevTime) => {
  //       const { minute, second } = prevTime;
  //       if (second === 59) {
  //         return { minute: minute + 1, second: 0 };
  //       } else {
  //         return { ...prevTime, second: second + 1 };
  //       }
  //     });
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
    
  // }, [startConversetion]);

useEffect(() => {
  if (!startConversetion) return;

  if (!hasTextBasedPlan) {
    toast.info("Kindly unmute your mic and speaker to participate in the meeting.");
  }
  setCheck(true);

  const interval = setInterval(() => {
    timeRef.current = (prevTime) => {
      const { minute, second } = prevTime;
      if (second === 59) {
        return { minute: minute + 1, second: 0 };
      } else {
        return { ...prevTime, second: second + 1 };
      }
    };
    setTime(timeRef.current); // Only set state when you need to trigger re-render
  }, 1000);

  return () => {
    clearInterval(interval);
  };

}, [startConversetion]);

  //Start Listening User Voice
  function startListening() {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      process.env.REACT_APP_MICROSOFT_AZURE,
      "eastus"
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const newRecognizer = new sdk.SpeechRecognizer(
          speechConfig,
          audioConfig
        );

        newRecognizer.recognizing = (s, e) => {
          // console.log(`RECOGNIZING: Text=${e.result.text}`);
          audioRef.current.muted = true;
          audioRef.current = null;
          audioQueue = [];

          audioRef.current.pause();
        };
        //   audioRef.current.currentTime = 0;


        newRecognizer.recognized = (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            // console.log(`RECOGNIZED: Text=${e.result.text}`);
            const newMessage = {
              sender: "You",
              text: e.result.text,
              time: new Date().toLocaleTimeString(),
            };
            const currentStreamText = streamTextRef.current;
            setChatHistory((prev) => [...prev, ...currentStreamText]);
            streamTextRef.current = [];
            setChatHistory((prev) => [...prev, newMessage]);

            setIsAnimationLoading(true);

            const sendMessage = {
              query: e.result.text,
              plan_name: userHasPlan.plan_name,
              voice: null,
              chat_type: chatType,
            };
            const jsonString = JSON.stringify(sendMessage);
            connection.current.send(jsonString);
            // console.log("sent Message", sendMessage);
          }
        };

        newRecognizer.canceled = (s, e) => {
          // console.log(`CANCELED: Reason=${e.reason}`);
          if (e.reason === sdk.CancellationReason.Error) {
            console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
          }
          stopListening();
        };

        newRecognizer.sessionStopped = (s, e) => {
          // console.log("\nSession stopped event.");
          stopListening();
        };
        newRecognizer.startContinuousRecognitionAsync();
        setRecognizer(newRecognizer);
        setIsListening(true);
      })
      .catch((error) => {
        console.log("Error accessing media devices:", error);
      });
  }
  

  //Stop Listening User Voice
  const stopListening = () => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(() => {
        recognizer.close();
        setRecognizer(null);
        setIsListening(false);
      });
    }
  };

  //Related to Listening User Voice
  useEffect(() => {
    return () => {
      if (recognizer) {
        stopListening();
      }
    };
  }, []);

  //Logic of Mute & Unmute Voice
  useEffect(() => {
    if (isSpeakerMuted) {
      setTimeout(mutePage, 0);
    } else {
      unmutePage();
    }
  }, [isSpeakerMuted]);

  //Mute Voice of chat
  const mutePage = () => {
    if (audioRef.current) {
      audioRef.current.muted = true;
    }
  };

  //Unmute Voice of chat
  const unmutePage = () => {
    if (audioRef.current) {
      audioRef.current.muted = false;
    }
  };

  //handle user upload file
  const uploadFile = async (uploadedFile) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("files", uploadedFile);
    setIsLoading(true);
    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/kb/real_time_upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { documents, images } = uploadResponse.data;
      const files = [...documents, ...images];

      await axios.post(`${process.env.REACT_APP_BASE_URL}/kb/real_time_train`, {
        userid: userId,
        sessionid: sessionId,
        files: files,
        topic_details: topic,
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error("Error in file upload or training:", error);
      toast.error(
        error?.response?.data?.Error ||
          "Error occurred during file upload or processing."
      );
      setIsLoading(false);
      return false;
    }
  };

  //handle send user input message
  const sendTextMessage = (chatInput) => {
    const sendMessage = {
      query: chatInput,
      plan_name: userHasPlan.plan_name,
      voice: null,
      chat_type: chatType,
    };
    const jsonString = JSON.stringify(sendMessage);
    connection.current.send(jsonString);
    // newStopListening();
  };

  //Create User Message Object Structure
  const createMessageObject = (text, file) => {
    const newMessage = {
      sender: "You",
      time: new Date().toLocaleTimeString(),
      text: text || null,
      file: file
        ? {
            name: file.name,
            type: file.type,
            url: file.type.startsWith("image/")
              ? URL.createObjectURL(file)
              : null,
          }
        : null,
    };
    return newMessage;
  };

  //handle user input message
  const handleSendMessage = async () => {
    if(!userprofileData){
      // toast.error("Please login to start your discussion...");
      handleSignInUpPopUpShow();
      return;
    }

    if (isLoading) return;

    if (chatInput.trim() === "" && !uploadedFile) return;

    const newMessage = createMessageObject(chatInput, uploadedFile);
    setIsLoading(true);

    try {
      if (chatInput.trim() !== "" && uploadedFile) {
        const uploadSuccess = await uploadFile(uploadedFile);
        if (uploadSuccess) {
          sendTextMessage(chatInput);
          setChatHistory((prevHistory) => [...prevHistory, newMessage]);
          setChatInput("");
          setUploadedFile(null);
          setIsAnimationLoading(true);
        }
      } else if (chatInput.trim() !== "") {
        sendTextMessage(chatInput);
        setChatHistory((prevHistory) => [...prevHistory, newMessage]);
        setChatInput("");
        setIsAnimationLoading(true);
      } else if (uploadedFile) {
        const uploadSuccess = await uploadFile(uploadedFile);
        if (uploadSuccess) {
          setChatHistory((prevHistory) => [...prevHistory, newMessage]);
          setUploadedFile(null);
          setIsAnimationLoading(true);
        }
      }
      setStreamText([]);
      const historyTemp = tempMessage?.map((message) => message);
      setChatHistory((prev) => [...prev, ...historyTemp]);
      setIsAnimationLoading(true);
      setTempMessage([]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Manually click on input field
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const updateDB = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update_db_feilds`,
        {
          userid: userId,
          time_used: formattedTime,
        }
      );
      // console.log("API call on unmount response:", response.data);
      setTime({ minute: 0, second: 0 });        
      setTest(false)
      setReminderCount(0);
    } catch (error) {
      console.error("Error during API call on unmount:", error);
    }
  };

  //End the Meeting
  const onCallEndClick = () => {
    if (audioRef.current) {
      audioRef.current.muted = true;
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      // audioRef.current = null;
      audioQueue = [];
      setChatHistory([]);
      setIsAnimationLoading(false);
    }
    
    if (connection.current) {
      connection.current.close(); 
      console.log("Socket Close");
      connection.current = null;
    }
    
    // updateDB();
    setCheck(false);
    setstartConversetion(false);
  };  

  //Handle File Field (checking condition)
  const handleFileChange = (event) => {
    if (userHasPlan?.plan_name === "Free_Trial") {
      toast.error("Upgrade your plan to upload !");
      return;
    }
    const file = event.target.files[0];
    const remainingUploads =
      userHasPlan.no_of_document_allowed - userHasPlan.no_of_document_uploaded;
    if (file?.length > remainingUploads) {
      toast.error(
        `Your ${userHasPlan.no_of_document_allowed} documents limit is over, kindly upgrade for more`
      );
      return;
    }
    if (file) {
      setUploadedFile(file);
    }
  };

  //Cancel Upload File
  const handleCancelUpload = () => {
    setUploadedFile(null);
  };

  //work on rendering style (1)
  const renderFilePreview = () => {
    if (!uploadedFile) return null;

    return (
      <div
        className="uploaded-file-preview v-center gap-2"
        style={{ marginBottom: "10px" }}
      >
        {isLoading ? (
          <Lottie animationData={animationData} style={{ width: 80 }} />
        ) : uploadedFile.type.startsWith("image/") ? (
          <div className="preview-image v-center">
            <img
              src={URL.createObjectURL(uploadedFile)}
              alt={uploadedFile.name}
              style={{
                maxWidth: "80px",
                maxHeight: "80px",
                objectFit: "contain",
              }}
            />
          </div>
        ) : uploadedFile.type === "application/pdf" ? (
          <div className="preview-pdf v-center gap-2">
            <Icon icon="mdi:file-pdf" width={24} height={24} />
            <span style={{ fontSize: "12px" }}>{uploadedFile.name}</span>
          </div>
        ) : (
          <div className="preview-default v-center gap-2">
            <span style={{ fontSize: "12px" }}>{uploadedFile.name}</span>
          </div>
        )}
        {!isLoading && (
          <Icon
            icon="mdi:close-circle"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={handleCancelUpload}
          />
        )}
      </div>
    );
  };

  //-------- End Meeting Function ------------

  // const defaultStaticImg = "../assets/dashboardimages/images/userImg1.png";

  return (
    <>
      <div className="container py-2 main-card meeting-card">
        {/* meeting containt */}
        <div className="d-flex justify-content-between meeting">
          <div className="pt-4">
              <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                <h5 className="text-center text-light">Topic: {topic}</h5>
                {startConversetion && (
                <div className="recoding my-2 ">
                  <img src={recodingImg} alt="recoding" />
                  <span>{formattedTime}/{meetingEndTime > formattedRemainingTime ? formattedRemainingTime : meetingEndTime }</span>
                </div>
                )}

              </div>
            
            <div className="chat-card py-2 d-flex gap-3">
              {personas?.length > 0 && personas?.map((persona, index)=>(
                <div key={index} className="chart-lead-card">
                  {!startConversetion && userprofileData &&(
                    <div className="edit-icons">
                      <div
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#editPersonaPopup"
                        onClick={()=>handleEditePersona(persona)}
                      >
                        <img
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          data-bs-title="Edit"
                          src={PencilSimpleLine}
                          alt="PencilSimpleLine"
                        />
                      </div>
                      <div
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#favoritePersonaPopup"
                        onClick={handleLikePopUpShow}
                      >
                        <img
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          data-bs-title="Like"
                          src={persona?.isFavourite && persona?.isFavourite == 1 ? LikedLogo : HeartLogo}
                          alt="HeartLogo"
                          onClick={()=>setPersonaOne(persona)}
                        />
                      </div>
                      <div
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#removePersonaPopup"
                      >
                        <img
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          data-bs-title="Delete"
                          src={TrashSimpleLogo}
                          alt="TrashSimple"
                          onClick={()=>setPersonaOne(persona)}
                        />
                      </div>
                    </div>
                  )}
                  <img
                    className="chat-card-img"
                    src={persona?.image ? persona?.image : businessmanLogo9}
                    alt="businessmanLogo9"
                  />
                  <h6>{persona?.name}</h6>
                  <p>{persona?.description?.slice(0,200)} ...</p>
                </div>
              ))}

            {!startConversetion && (
              <div>
              <div className="add-persona d-flex align-items-center justify-content-center flex-column" onClick={handleAddPopUpShow}>
                <img
                  type="button"
                  // data-bs-toggle="modal"
                  // data-bs-target="#addPersonaPopup"
                  src={PlusCircleLogo}
                  alt="PlusCircle"
                  width={50}
                  // id="AddMainPerson"
                />
                <h5>Add Persona</h5>
                <i
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  data-bs-title="Lorem ipsum dolor sit amet consectetur. Nulla quam fermentum sapien"
                  className="fa-solid fa-circle-info"
                />
              </div>
              {personas?.length > 0 && !startConversetion && (
                <button onClick={handleDisccussion} disabled={isLoading} className="signInn mt-3">{isLoading ? 'Wait a while...' : 'Start Discussion'}</button>
              )}
              </div>
            )}
            </div>
            <div className="line-arrow">
              <img src={LineLogo} alt="line img" />
            </div>
            <div className="chart-lead-card2">
              <div className="d-flex align-items-center gap-3 user-btn">
                <img src={userprofileData?.picture ? userprofileData?.picture : animojiLogo} alt="" width={60} />
                {/* <h5>{profileData?.username === "Guest" ? "You" : profileData?.username}</h5> */}
                <h5>You</h5>
              </div>
              <p className="mt-2 pt-2 mb-1">
                Discussion Topic : 
                <span style={{fontSize: "15px"}}>{" "}{topic}</span>
              </p>
            </div>
          </div>

          {/* Transcript */}
          {startConversetion && (
            <div className="transcript mt-5">
              <p className="transcript-heading">Transcript</p>
              {/* <div className="d-flex trans-body flex-column me-2 gap-2 "> */}



                <div
                  className="trans-body me-2 gap-2 d-flex flex-column"
                  ref={chatContainerRef}
                >
                  {chatHistory
                    ?.reduce((unique, message) => {
                      if (
                        !unique.some(
                          (msg) =>
                            msg.text === message.text && msg.time === message.time
                        )
                      ) {
                        unique.push(message);
                      }
                      return unique;
                    }, [])
                    ?.map((message, index) => {
                      if (!message?.sender) {
                        return null;
                      }
  
                      const isSenderYou = message?.sender === "You";
  
                      return (
                        <div
                          key={index}
                          className={isSenderYou ? "chart-message-user1 d-flex flex-column" : "chart-message-user1 user2 d-flex flex-column"}
                        >
                          <div className={`d-flex align-items-start gap-2 ${isSenderYou ? 'justify-content-end' : 'justify-content-start'}`} >
                            {isSenderYou ? (
                              <>
                                <div className="d-flex flex-column">
                                  {/* If both text and file exist, render them together */}
                                  {message?.file && (
                                    <>
                                      {message.file.type.startsWith("image/") ? (
                                        <img
                                          src={message.file.url}
                                          alt="uploaded"
                                          style={{
                                            width: "120px",
                                            height: "80px",
                                            // marginLeft: "10px",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      ) : (
                                        <a
                                          href={message.file.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            display: "block",
                                            marginTop: "10px",
                                            textDecoration: "underline",
                                            color: "#0000FF",
                                          }}
                                        >
                                          {message.file.name}
                                        </a>
                                      )}
                                    </>
                                  )}
                                  {message?.text && (
                                    <p
                                      //Me -> You
                                      // className="px-3 py-1 text-break"
                                      dangerouslySetInnerHTML={{
                                        __html: message?.text,
                                      }}
                                    />
                                  )}
                                </div>
                                <img
                                  style={{ borderRadius: 100 }}
                                  src={
                                    profileData?.picture ||
                                    "../../assets/dashboardimages/images/animoji.png"
                                  }
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    message?.image ||
                                    "./businessman9.png"
                                  }
                                  alt=""
                                  style={{ borderRadius: 100 }}
                                />
                                <div className="">
                                  {message?.file && (
                                    <>
                                      {message.file.type.startsWith("image/") ? (
                                        <img
                                          src={message.file.url}
                                          alt="uploaded"
                                          // style={{
                                          //   maxWidth: "200px",
                                          //   borderRadius: "10px",
                                          // }}
                                        />
                                      ) : (
                                        <a
                                          href={message.file.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            display: "block",
                                            marginTop: "10px",
                                            textDecoration: "underline",
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Icon
                                            icon="mdi:file-pdf"
                                            width={14}
                                            height={14}
                                          />
                                          {message.file.name}
                                        </a>
                                      )}
                                    </>
                                  )}
                                  {message?.text && (
                                    <p
                                      //chat lead but all goes to green
                                      dangerouslySetInnerHTML={{
                                        __html: message?.text,
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {/* <div className="bottom d-flex">
                            <span className="d-flex gap-2 fw-normal"> */}
                              <span>{message?.sender}{" "}
                              {message?.time}</span>
                            {/* </span>
                          </div> */}
                        </div>
                      );
                    })}
  
                  {streamText?.map((message, index) => {
                    if (!message?.sender) {
                      return null;
                    }
  
                    const isSenderYou = message?.sender === "You";
  
                    return (
                      <div
                        key={index}
                        className={isSenderYou ? "chart-message-user1 d-flex flex-column" : "chart-message-user1 user2 d-flex flex-column"}
                      >
                        <div className={`d-flex align-items-start gap-2 ${isSenderYou ? 'justify-content-end' : 'justify-content-start'}`}>
                          {isSenderYou ? (
                            <>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: message?.text,
                                }}
                              />
                              <img
                                style={{ borderRadius: 100 }}
                                src={
                                  profileData?.picture || "./businessman9.png"
                                }
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                            <div>
                              <img
                                // className="rounded-circle"
                                style={{ borderRadius: 100 }}
                                src={
                                  message.image ||
                                  "../../assets/dashboardimages/images/animoji.png"
                                }
                                alt=""
                              />
                              <p
                                //problem here
                                dangerouslySetInnerHTML={{
                                  __html: message?.text,
                                }}
                              />
                            </div>
                            </>
                          )}
                        </div>
                        {/* <div className="bottom d-flex">
                          <span className="d-flex gap-2 fw-normal"> */}
                            <span>{message?.sender}{" "}{message?.time}</span>
                          {/* </span>
                        </div> */}
                      </div>
                    );
                  })}


                {isAnimationLoading && (
                  <div className="mb-auto">
                  <ThreeDots color="#2C2C2C" height={10} width={60} />
                  </div>
                )}
                </div>


              {/* </div> */}
            </div>
          )}

        </div>
        {/* meeting Input */}
        {startConversetion && (
        <div className="mb-1">
          {renderFilePreview()}
          <div className="d-flex align-items-center justify-content-center gap-3 mic">
            <img 
              onClick={
                hasTextBasedPlan
                  ? () => toast.error("Upgrade your plan to have voice chat!")
                  : () => {
                    isSpeakerMutedRef.current = !isSpeakerMutedRef.current;
                    setIsSpeakerMuted(!isSpeakerMuted);
                  }
              }
              src={isSpeakerMuted ? muteIcon : speekerIcon} alt="muteIcon" 
            />

            {isListening ? (
              <img
                src={micIcon}
                alt="muted"
                onClick={stopListening}
              />
            ) : (
              <img
                src={muteMicIcon}
                alt="Unmuted"
                onClick={
                  hasTextBasedPlan
                    ? () =>
                        toast.error("Upgrade your plan to have voice chat!")
                    : startListening
                }
              />
            )}

            <img 
              src={callIcon} 
              alt="callIcon" 
              onClick={() => {
                onCallEndClick();
              }}
            />
          </div>
          <div className="meeting-input">

            {/* <div className="w-100"> */}
              {/* {renderFilePreview()} */}
              <input
                className="form-control rounded-2 ps-3"
                type="text"
                placeholder="Message Slara"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
            {/* </div> */}

            <div className="input-icons">
              <img src={attachIcon} alt="" onClick={handleIconClick}/>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <img 
                onClick={handleSendMessage}
                src={rightArrowIcon} 
                alt="" 
                width={30} 
                style={{cursor: topic === '' ? "no-drop" : 'pointer'}}/>
            </div>
          </div>
        </div>
        )}
      </div>

    {!startConversetion && (
      <>
      <AddPersonaModal 
        addPopUpShow={addPopUpShow}
        handleAddPopUpClose={handleAddPopUpClose}
        
        customAddPopUpShow={customAddPopUpShow}
        handleCustomShow={handleCustomShow}
        handleCustomClose={handleCustomClose}
      />

      <EditPersonaModal 
        personaOne={personaOne}
        handleEditPopUpClose={handleEditPopUpClose}
        editPopUp={editPopUp}
      />
      <FavoritePersonaModal 
        personaOne={personaOne}
        handleLikePopUpClose={handleLikePopUpClose}
        likePopUp={likePopUp}
      />
      <RemovePersonaModal personaOne={personaOne}/>
      <OnlySignInUpModal 
        signInUpPopUp={signInUpPopUp} 
        handleSignInUpPopUpClose={handleSignInUpPopUpClose}
        handleSignInUpPopUpShow={handleSignInUpPopUpShow}
      />
      </>
    )}

    </>
  );
}

export default MeetingContaint;
